import { React, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { IMAGE_PATH_PREFIX } from "../../api";
import { getAllOrgUsers } from "../../redux/slice/organisationsSlice";
import OrganisationWrapper from "../../components/wrappers/OrganisationWrapper";
import OrganisationInviteUser from "./OrganisationInviteUser";
import Heading2 from "../../components/headings/Heading2";
import Modal from "../../components/modal/Modal";
import Button1 from "../../components/buttons/Button1";

const OrganisationUsers = ({ setProgress }) => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { t } = useTranslation(); // constant to use this hook

  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(true);

  const {
    getAllOrgUsers: { allOrgUsers },
  } = useSelector((state) => state.organisation);

  useEffect(() => {
    setProgress(40);
    setLoader(true);
    dispatch(getAllOrgUsers()).then(() => {
      setProgress(100);
      setLoader(false);
    });
  }, [dispatch]);

  return (
    <OrganisationWrapper>
      <Button1 className="cd-common-anchor-tag" onClick={(e) => setOpenModal(!openModal)}>
        {t("inviteUser")}
      </Button1>
      <Heading2>{t("orgUsers")} </Heading2>
      {allOrgUsers?.data?.detail && (
        <div className="cd-err-msg">{allOrgUsers?.data?.detail[0]?.msg}</div>
      )}
      {allOrgUsers?.data?.detail?.detail && (
        <div className="cd-err-msg">{allOrgUsers?.data?.detail?.detail[0]?.msg}</div>
      )}
      <div
        className={`cd-organisations-user-list-container ${loader ? "not-appeared" : "appeared"}`}
      >
        {allOrgUsers?.users?.map((user, index) => (
          <div key={index} className="cd-organisations-user-list-element">
            <div>
              <img
                className="cd-user-profile-thumbnail"
                src={`${IMAGE_PATH_PREFIX + user.user.profile_photo}`}
                alt=""
              />
            </div>
            <div>
              {t("email")} : {user.user.email}
            </div>
            <div>
              {t("username")} : {user.user.username}
            </div>
            <div>
              {t("activeStatus")} :{" "}
              <span className={`${user.is_active === true ? "cd-success-msg" : "cd-err-msg"}`}>
                {" "}
                {user.is_active === true ? t("active") : t("deactivated")}{" "}
              </span>
            </div>
            <div>
              <Link
                className="cd-common-anchor-tag"
                to={`/${slug}/view-org-user/${user.user.uuid}`}
              >
                {t("details")}
              </Link>
            </div>
          </div>
        ))}
      </div>
      {openModal && (
        <Modal handleModal={(e) => setOpenModal(!openModal)}>
          <OrganisationInviteUser />
        </Modal>
      )}
    </OrganisationWrapper>
  );
};

export default OrganisationUsers;
