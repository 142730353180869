import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { TmASSIGN_PROJECT_PERMISSIONS, TmPROJECT_USER_PERMISSIONS } from "../../api";
import Heading2 from "../../components/headings/Heading2";
import InputCheckbox from "../../components/inputs/InputCheckbox";
import Label1 from "../../components/labels/Label1";
import axiosFetch from "../../axiosConfig";
import Button1 from "../../components/buttons/Button1";

const TmProjectUserPermissions = ({ projectId, userId, userName }) => {
  // eslint-disable-next-line no-unused-vars
  const [projectUserPermission, setProjectUserPermission] = useState({});
  const { t } = useTranslation(); // const to use this hook
  const [permissionCodenameArray, setPermissionCodenameArray] = useState([]);
  const [assignPermissionResponse, setAssignPermissionResponse] = useState({});
  const [loader, setLoader] = useState(true);

  // get the project user permission
  useEffect(() => {
    async function getProjectUserPermission() {
      setLoader(true);
      try {
        const resp = await axiosFetch.get(
          TmPROJECT_USER_PERMISSIONS + projectId + `?user_uid=${userId}`
        );
        setProjectUserPermission(resp.data);
        if (resp.status === 200) {
          resp.data?.user_permission.map((permission, index) => {
            setPermissionCodenameArray((prev) => [...prev, permission.codename]);
          });
          setLoader(false);
        }
      } catch (error) {
        setProjectUserPermission(error.response);
        setLoader(false);
      }
    }
    getProjectUserPermission();
  }, []);

  // get user permissions from redux
  const {
    getProjectPermissions: { allProjectPermissions },
  } = useSelector((state) => state.taskManagement);

  // handle the permission change
  const handlePermission = async (codename) => {
    let tempArray = [...permissionCodenameArray];
    let index = permissionCodenameArray.indexOf(codename);
    if (index !== -1) {
      tempArray.splice(index, 1);
    } else {
      tempArray.push(codename);
    }
    setPermissionCodenameArray(tempArray);
  };

  // call the api of assign permission
  let assignPermissionPayload = {
    user_uid: userId,
    codename: permissionCodenameArray,
  };
  const assignPermission = async () => {
    try {
      const resp = await axiosFetch.post(TmASSIGN_PROJECT_PERMISSIONS, assignPermissionPayload);
      setAssignPermissionResponse(resp);
    } catch (error) {
      setAssignPermissionResponse(error.response);
    }
  };

  return (
    <>
      <Heading2 align="center">{t("projectPermissions")} </Heading2>
      <div className="" align="center">
        <strong>
          {t("username")} : {userName}
        </strong>
      </div>
      {loader && <span className="cd-success-msg"> {t("loading")}...</span>}
      <div className={`cd-input-container ${loader ? "not-appeared" : "appeared"}`}>
        {allProjectPermissions.map((permission) => {
          return (
            <div key={permission.codename}>
              <InputCheckbox
                id={permission.codename}
                value={permission.codename}
                name={permission.codename}
                onChange={() => handlePermission(permission.codename)}
                checked={permissionCodenameArray?.includes(permission.codename) ? true : ""}
              ></InputCheckbox>
              <Label1 className="cd-checkbox-label" htmlFor={permission.codename}>
                {permission.permission}
              </Label1>
            </div>
          );
        })}
        <Button1 onClick={assignPermission}>{t("assign")} </Button1>
        {assignPermissionResponse?.status === 200 && (
          <div className="cd-success-msg">{t("permissionUpdateMsg")} !</div>
        )}
        {assignPermissionResponse?.data?.detail && (
          <div className="cd-err-msg">{assignPermissionResponse?.data?.detail[0]?.msg}</div>
        )}
        {assignPermissionResponse?.data?.detail?.detail && (
          <div className="cd-err-msg">{assignPermissionResponse?.data?.detail?.detail[0]?.msg}</div>
        )}
      </div>
    </>
  );
};

export default TmProjectUserPermissions;
