import React from "react";
const DashboardSidebar = (props) => {
  return (
    <>
      <div className="cd-organisation-strip-controls">{props.children}</div>
    </>
  );
};

export default DashboardSidebar;
