import { React, useState } from "react";
import DashboardWrapper from "../wrappers/DashboardWrapper";

export const ChangeCurrency = () => {
  const [currency, setCurrency] = useState(localStorage.getItem("currencyCMS"));
  // function to handle the currency change
  const handleCurrencyChange = async (e) => {
    setCurrency(e.target.value);
    localStorage.setItem("currencyCMS", e.target.value);
  };

  return (
    <DashboardWrapper>
      <div className="cd-currency-changer-container">
        <div className="cd-input-container">Change Currency</div>
        <div className="cd-input-container">
          <select
            name="currencyChanger"
            id="currencyChanger"
            className="cd-input-field"
            value={currency}
            onChange={handleCurrencyChange}
          >
            <option value="INR">Rupees</option>
            <option value="USD">Dollar</option>
            <option value="GBP">Pound</option>
            <option value="EUR">Euro</option>
          </select>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export const currencyMapping = {
  INR: { symbol: "₹", priceKey: "price_inr", salePriceKey: "sale_price_inr" },
  USD: { symbol: "$", priceKey: "price_usd", salePriceKey: "sale_price_usd" },
  GBP: { symbol: "£", priceKey: "price_pond", salePriceKey: "sale_price_pond" },
  EUR: { symbol: "€", priceKey: "price_euro", salePriceKey: "sale_price_euro" },
};
