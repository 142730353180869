import { React, useState } from "react";
import { useTranslation } from "react-i18next";

import axiosFetch from "../../axiosConfig";
import { ORGANISATION_SEARCH_USER, IMAGE_PATH_PREFIX, ORGANISATION_INVITE_USER } from "../../api";
import Heading2 from "../../components/headings/Heading2";
import Loader2 from "../../components/loaders/Loader2";
import Search from "../../components/search/Search";
import Button1 from "../../components/buttons/Button1";

const OrganisationInviteUser = () => {
  const [keyword, setKeyword] = useState("");
  const { t } = useTranslation(); // contant to use this hook
  const [searchResponse, setSearchResponse] = useState();
  const [invitationResponse, setInvitationResponse] = useState();
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [loader, setLoader] = useState(false);

  // function to call the API of search user
  const searchUser = async (e) => {
    e.preventDefault();
    let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i;
    if (!emailRegex.test(keyword)) {
      setEmailValidationError("Please enter a valid email");
      setSearchResponse();
      return;
    }
    if (keyword.length >= 3) {
      setEmailValidationError();
      setLoader(true);
      try {
        const resp = await axiosFetch.get(ORGANISATION_SEARCH_USER + `?email=${keyword}`);
        setSearchResponse(resp.data);
        if (resp.status === 200) {
          setLoader(false);
        }
      } catch (error) {
        setSearchResponse(error.response.data);
        setLoader(false);
      }
    }
  };
  // console.log(searchResponse);

  // function to call the API of invite user
  const inviteUser = async () => {
    try {
      const resp = await axiosFetch.post(
        ORGANISATION_INVITE_USER + `?email=${searchResponse?.frontend_user?.email}`
      );
      if (resp.status === 200) {
        setInvitationResponse(resp);
      }
    } catch (error) {
      setInvitationResponse(error.response);
    }
  };

  const isDisabled = "";

  return (
    <>
      <div>
        <Heading2 align="center">{t("inviteUser")} </Heading2>
      </div>
      <br />
      <div className="cd-search-user-container" align="center">
        <form onSubmit={searchUser}>
          <div>
            <Search
              placeholder={t("placeholderEmail")}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            ></Search>
          </div>
          {emailValidationError && <div className="cd-err-msg">{emailValidationError}</div>}
          <div>
            <Button1 type="submit" disabled={isDisabled}>
              {t("search")} {loader && <Loader2 />}
            </Button1>
          </div>
        </form>
        <br />
        <div className="cd-search-result-user">
          {searchResponse?.frontend_user && !emailValidationError && (
            <>
              <div>
                <img
                  className="cd-user-profile-thumbnail"
                  src={`${IMAGE_PATH_PREFIX + searchResponse?.frontend_user?.profile_photo}`}
                  alt="user"
                />
              </div>
              <div>{searchResponse?.frontend_user?.email}</div>
              <Button1 onClick={inviteUser}> {t("invite")} </Button1>
              {invitationResponse?.status === 200 && (
                <div className="cd-success-msg cd-msg-container">{t("emailSentMsg")} </div>
              )}
              {invitationResponse?.data?.detail && (
                <div className="cd-err-msg">{invitationResponse?.data?.detail[0]?.msg}</div>
              )}
              {invitationResponse?.data?.detail?.detail && (
                <div className="cd-err-msg">{invitationResponse?.data?.detail?.detail[0]?.msg}</div>
              )}
            </>
          )}
          {searchResponse?.detail?.detail && (
            <div className="cd-err-msg">{searchResponse?.detail?.detail[0]?.msg}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrganisationInviteUser;
