import { React, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTimeZones } from "../../redux/slice/userSlice";
import { REGISTER_ENDPOINT } from "../../api";
import axiosFetch from "../../axiosConfig";
import { useTranslation } from "react-i18next";
import Card1 from "../../components/cards/Card1";
import Label1 from "../../components/labels/Label1";
import InputEmail from "../../components/inputs/InputEmail";
import InputField from "../../components/inputs/InputField";
import InputPassword from "../../components/inputs/InputPassword";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import Loader from "../../components/loaders/Loader";

const SignUp = () => {
  // constant to use useTranslation() hook
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [language, setLanguage] = useState("");
  const [timezone, setTimezone] = useState("Asia/Kolkata");

  // constant to store the response got from register user API
  const [registerResponse, setRegisterResponse] = useState("");
  const [loader, setLoader] = useState(false);

  // payload to send the user details with API call
  const userDetail = {
    email: email,
    username: userName,
    first_name: firstname,
    last_name: lastname,
    password: password,
    language: language,
    timezone: timezone,
  };
  // console.log(userDetail);

  // getting all the timezones list from userSlice
  useEffect(() => {
    dispatch(getTimeZones());
  }, []);

  // getting the timezones list from user state
  const {
    getTimeZone: { timeZonesList },
  } = useSelector((state) => state.user);

  //input validations, start

  const [inputError, setInputError] = useState({
    email: "",
    password: "",
    userName: "",
    roleId: "",
  });

  //email validation
  const handleEmail = (value) => {
    setEmail(value);
    let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i;
    if (!emailRegex.test(email)) {
      setInputError({ ...inputError, email: `${t("invalidEmail")}` });
    } else {
      setInputError({ ...inputError, email: "" });
    }
  };

  //username validation
  const handleUserName = (value) => {
    setUserName(value);
    let userRegex = /^[a-zA-Z0-9_]{6,30}$/;
    if (userName.length < 6)
      setInputError({
        ...inputError,
        userName: `${t("userNameValidationMsg")}`,
      });
    else if (!userRegex.test(userName)) setInputError({ ...inputError, userName: "Invalid Input" });
    else setInputError({ ...inputError, userName: "" });
  };

  //password validation
  const handlePassword = (value) => {
    setPassword(value);
    let passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password))
      setInputError({
        ...inputError,
        password: `${t("passwordValidationMsg")}`,
      });
    else setInputError({ ...inputError, password: "" });
  };
  // to disable the sign up button until all fields are filled
  const isDisabled =
    email.length < 4 ||
    userName.length < 6 ||
    password.length < 8 ||
    password !== password2 ||
    inputError?.email ||
    inputError?.password ||
    inputError?.userName
      ? true
      : false;

  //calling the register user api
  const registerUser = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(REGISTER_ENDPOINT, userDetail);
      setRegisterResponse(resp);
      if (resp) {
        setLoader(false);
      }
    } catch (error) {
      setRegisterResponse(error?.response);
      setLoader(false);
    }
  };

  //redirect user to dashboard if user is already login
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div>
      <Card1>
        <div>
          <Heading2> {t("signUp")}</Heading2>
        </div>
        <div className="cd-input-container">
          <Label1>{t("email")}:</Label1>
          <InputEmail
            className="cd-input-field"
            name="cd_reg_email"
            placeholder={t("placeholderEmail")}
            onChange={(e) => handleEmail(e.target.value)}
          ></InputEmail>
        </div>

        {/* show the error if email key of inputError has a value */}
        <div className="cd-input-container">
          {inputError?.email && <div className="cd-err-msg">{inputError.email}</div>}
        </div>
        <div className="cd-input-container">
          <Label1>{t("username")}:</Label1>
          <InputField
            className="cd-input-field"
            name="cd_reg_username"
            placeholder={t("placeholderUsername")}
            onChange={(e) => handleUserName(e.target.value)}
          ></InputField>
        </div>

        {/* show the error if userName key of inputError has a value */}
        <div className="cd-input-container">
          {inputError?.userName && (
            <div className="cd-err-msg">
              {inputError.userName}
              <div>
                space and special characters <br /> not allowed except"_"
              </div>
            </div>
          )}
        </div>
        <div className="cd-input-container">
          <Label1>{t("firstName")}:</Label1>
          <InputField
            className="cd-input-field"
            name="cd_reg_fname"
            placeholder={t("placeholderFirstName")}
            onChange={(e) => setFirstname(e.target.value)}
          ></InputField>
        </div>
        <div className="cd-input-container">
          <Label1>{t("lastName")}:</Label1>
          <InputField
            className="cd-input-field"
            name="cd_reg_lname"
            placeholder={t("placeholderLastName")}
            onChange={(e) => setLastname(e.target.value)}
          ></InputField>
        </div>
        <div className="cd-input-container">
          <Label1>{t("password")}:</Label1>
          <InputPassword
            className="cd-input-field"
            name="cd_reg_password"
            placeholder={t("placeholderPassword")}
            onChange={(e) => handlePassword(e.target.value)}
          ></InputPassword>
        </div>

        {/* show the error if password key of inputError has a value */}
        <div className="cd-input-container">
          {inputError?.password && (
            <div className="cd-err-msg">
              {inputError.password}
              <div>
                Password should have at least
                <br />a capital & small letter,
                <br />a special character and number
              </div>
            </div>
          )}
        </div>
        <div className="cd-input-container">
          <Label1>{t("confirmPswrd")}:</Label1>
          <InputPassword
            className="cd-input-field"
            name="cd_reg_password_confirm"
            placeholder={t("placeholderConfirmPswrd")}
            onChange={(e) => setPassword2(e.target.value)}
          ></InputPassword>
        </div>
        <div className="cd-input-container">
          <Label1>{t("language")} :</Label1>
          <select name="cd_reg_language" id="" onChange={(e) => setLanguage(e.target.value)}>
            <option value=""> {t("selectLanguage")} </option>
            <option value="hn">{t("hindi")}</option>
            <option value="en">{t("english")}</option>
          </select>
        </div>
        <div className="cd-input-container">
          <Label1>{t("timezone")} :</Label1>
          <select
            name="cd_reg_select"
            id=""
            value={timezone}
            onChange={(e) => setTimezone(e.target.value)}
          >
            <option value="">{t("selectTimezone")}</option>
            {/* render all timezones */}
            {timeZonesList?.map((data, index) => (
              <option key={index} value={data.code}>
                {data.title}
              </option>
            ))}
          </select>
        </div>

        {/* code block to show the success msg */}
        {registerResponse?.status === 201 && (
          <div className="cd-success-msg">
            {t("registrationSuccessMsg")},
            <br />
            {t("verfiyEmailMsg")}
          </div>
        )}

        {/* code block to show the error msg */}
        {registerResponse?.data?.detail && (
          <div className="cd-err-msg">{registerResponse?.data?.detail?.detail[0]?.msg}</div>
        )}
        {loader && <Loader />}
        <Button1 className="cd-btn" disabled={isDisabled} onClick={registerUser}>
          {t("submit")}
        </Button1>
        <div className="cd-accountexist-container">
          <div>{t("alreadyHaveAcc")}</div>
          <Link className="cd-common-anchor-tag" to="/login">
            {" "}
            {t("login")}{" "}
          </Link>
        </div>
      </Card1>
    </div>
  );
};

export default SignUp;
