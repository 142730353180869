import { React, useEffect, useMemo, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getProjectTasks } from "../../redux/slice/taskManagementSlice";
import { ORGANIZATION_FILE_PREVIEW, TmREMOVE_TAG } from "../../api";

import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import Modal from "../../components/modal/Modal";
import Pagination from "../../components/pagination/Pagination";
import GoToPage from "../../components/pagination/GoToPage";
import TmUpdateTask from "./TmUpdateTask";
import TmAssignTask from "./TmAssignTask";
import TmAddSubTask from "./TmAddSubTask";
import TmUpdateCustomColumnValues from "./TmUpdateCustomColumnValues";
import TmDeleteCustomColumn from "./TmDeleteCustomColumn";
import TmAddTaskComment from "./TmAddTaskComment";
import TmAssignTag from "./TmAssignTag";
import axiosFetch from "../../axiosConfig";

const TmAllTasks = (props) => {
  // constant to use useDispatch hook
  const dispatch = useDispatch();
  // constant to use useNavigate hook
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [taskToBeUpdate, setTaskToBeUpdate] = useState({});
  const [taskUpdated, setTaskUpdated] = useState(false);
  const [taskToBeAssign, setTaskToBeAssign] = useState({});
  const [taskToAssignTag, setTaskToAssignTag] = useState({});
  const [subTaskToBeAdded, setSubTaskToBeAdded] = useState({});
  const [columnToBeDeleted, setColumnToBeDeleted] = useState(false);
  const [columnValueToBeUpdated, setColumnValueToBeUpdated] = useState(false);
  const [commentToBeAdded, setCommentToBeAdded] = useState({});
  const [showSubTask, setShowSubTask] = useState({});
  const [limit] = useState(10);
  // get the page number from URL search param
  const page = new URLSearchParams(window.location.search).get("page");
  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [inputPage, setInputPage] = useState("");

  const projectId = props.projectId;
  // getting tasks from taskManagement state of redux
  const {
    getTasks: { allTasks },
  } = useSelector((state) => state.taskManagement);

  // getting project details from taskManagement state of redux
  const {
    getProjectDetails: { projectDetails },
  } = useSelector((state) => state.taskManagement);

  // getting the project tasks
  useEffect(() => {
    setLoader(true);
    dispatch(
      getProjectTasks({
        projectId: projectId,
        limit: limit,
        offset: limit * (currentPage - 1),
      })
    ).then(() => {
      setLoader(false);
    });
  }, [currentPage, props.newTaskCreated, props.customColumnCreated, taskUpdated]);

  // functions to handle the pagination
  const handlePrev = () => {
    let tempCurrentPage = currentPage;
    if (currentPage > 1) {
      tempCurrentPage -= 1;
      setCurrentPage(parseInt(tempCurrentPage));
      navigate(`?page=${tempCurrentPage}`);
      if (tempCurrentPage === 1) {
        navigate(window.location.pathname);
        return;
      }
    }
  };
  // console.log(Math.round(allTasks?.total / limit));

  const handleNext = () => {
    setCurrentPage(parseInt(currentPage));
    if (currentPage >= Math.ceil(allTasks?.total / limit)) return;
    setCurrentPage(currentPage + 1);
    navigate(`?page=${currentPage + 1}`);
  };

  const handleInputPage = () => {
    if (inputPage < 0 || inputPage === "" || inputPage > Math.ceil(allTasks?.total / limit)) return;
    setCurrentPage(parseInt(inputPage));
    if (inputPage == 1) navigate(window.location.pathname);
    else navigate(`?page=${inputPage}`);
  };

  // function to call the API of remove tag
  const removeTag = async (payload) => {
    try {
      const resp = await axiosFetch.post(TmREMOVE_TAG, payload);
      if (resp.status === 201) {
        dispatch(
          getProjectTasks({
            projectId: projectId,
            limit: limit,
            offset: limit * (currentPage - 1),
          })
        );
      }
    } catch (error) {
      // console.log(error.response);
    }
  };

  // function to call the API of task media preview
  const getMediaPreview = async (task_id, media_id) => {
    try {
      const resp = await axiosFetch.get(
        ORGANIZATION_FILE_PREVIEW + media_id + `?task_id=${task_id}`
      );
      return resp.data.link;
    } catch (error) {
      return "Media Preview Fail";
    }
  };

  // memoize the task media preview URL
  const mediaURL = useMemo(() => {
    const mediaURL = {};
    if (allTasks?.tasks) {
      allTasks?.tasks?.map((task, index) => {
        if (task?.medias) {
          Object.entries(task.medias).map(async ([index1, media_id]) => {
            mediaURL[task?.tuid] = await getMediaPreview(task?.tuid, media_id);
          });
        }
      });
      return mediaURL;
    }
  }, [allTasks, currentPage]);

  return (
    <div className={`cd-task-list-container ${loader ? "not-appeared" : "appeared"}`}>
      <Heading2>Tasks :</Heading2>
      {allTasks?.total !== 0 ? (
        <table className="cd-task-list-table" border="1px thick black">
          <tbody>
            <tr>
              <th>Title</th>
              <th>Comments</th>
              <th>Medias</th>
              <th>Tag</th>
              <th>Assignee</th>
              <th>Estimated Hours</th>
              <th>Deadline</th>
              <th>Subtask</th>
              {projectDetails?.columns?.map((column, index1) => (
                <th key={column.cuid}>
                  <span>{column?.title}</span>
                  <span
                    onClick={() =>
                      setColumnToBeDeleted({
                        columnId: column.cuid,
                        index: index1,
                      })
                    }
                    className="cd-edit-tag-icon"
                  >
                    <img height="15px" src="/delete.png" alt="delete" />
                  </span>
                </th>
              ))}
            </tr>
            {allTasks?.tasks?.map((task, index) => (
              <Fragment key={task.tuid}>
                <tr
                  className={showSubTask?.taskid == task?.tuid ? "cd-task-aria-expanded" : ""}
                  key={index}
                >
                  <td>
                    <span>{task?.title}</span>
                    <span
                      onClick={() => setTaskToBeUpdate({ task: task, index: index })}
                      className="cd-edit-tag-icon"
                    >
                      <img
                        height="15px"
                        src="/edit.png"
                        alt="edit"
                        align="right"
                        title="Edit task"
                      />
                    </span>
                    {task?.subtask?.length > 0 && (
                      <span
                        onClick={() => {
                          let tempVar = showSubTask?.taskid ? {} : { taskid: task.tuid };
                          setShowSubTask(tempVar);
                        }}
                        className="cd-edit-tag-icon"
                      >
                        <img
                          height="15px"
                          src="/dropdown-icon.png"
                          alt="subtask"
                          align="right"
                          title="View subtask"
                          aria-expanded={showSubTask?.taskid === task?.tuid ? "true" : "false"}
                        />
                      </span>
                    )}
                    {taskToBeUpdate.index === index && (
                      <Modal handleModal={() => setTaskToBeUpdate("")}>
                        <TmUpdateTask
                          task={taskToBeUpdate.task}
                          projectId={projectId}
                          limit={limit}
                          currentPage={currentPage}
                        />
                      </Modal>
                    )}
                  </td>
                  <td>
                    <div className="cd-view-comments" align="center">
                      <span
                        className="cd-comment-icon"
                        onClick={() => setCommentToBeAdded({ task: task, index: index })}
                      >
                        <img src="/comment.png" />
                      </span>
                    </div>
                    {commentToBeAdded.index === index && (
                      <Modal handleModal={() => setCommentToBeAdded("")}>
                        <TmAddTaskComment taskId={commentToBeAdded.task.tuid} />
                      </Modal>
                    )}
                  </td>
                  <td>
                    {task?.medias ? (
                      Object.entries(task.medias).map(([index, mediaId]) => {
                        const previewLink = mediaURL[task?.tuid];
                        return (
                          <>
                            <a
                              key={index}
                              className="cd-common-anchor-tag"
                              href={`https://drive.google.com/file/d/${mediaId}/view?usp=drivesdk`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View File
                            </a>
                            <img className="cd-task-media-image-thumb" src={previewLink} alt="" />
                          </>
                        );
                      })
                    ) : (
                      <span>No Medias</span>
                    )}
                  </td>
                  <td>
                    {" "}
                    {task.tags_assigned.map((tag, index) => (
                      <span
                        className="cd-tag-title"
                        key={tag.tuid + index}
                        style={{ backgroundColor: tag.tag.color, marginRight: "5px" }}
                      >
                        {tag.tag.title}{" "}
                        <span
                          onClick={() => removeTag({ task_id: task.tuid, tag_id: tag.tag.tuid })}
                          className="cd-cross-mark"
                        >
                          &times;
                        </span>
                      </span>
                    ))}
                    <span
                      onClick={() => setTaskToAssignTag({ task: task, index: index })}
                      className="cd-edit-tag-icon"
                      align="center"
                    >
                      <img height="15px" src="/edit.png" alt="edit" align="center" />
                    </span>
                    {taskToAssignTag.index === index && (
                      <Modal handleModal={() => setTaskToAssignTag("")}>
                        <TmAssignTag
                          task={taskToAssignTag.task}
                          projectId={projectId}
                          limit={limit}
                          currentPage={currentPage}
                        />
                      </Modal>
                    )}
                  </td>
                  <td>
                    {task.assigned_to.map((assignee, index) => (
                      <div key={index}>{assignee.user.username}</div>
                    ))}
                    <span
                      onClick={() => setTaskToBeAssign({ task: task, index: index })}
                      className="cd-edit-tag-icon"
                    >
                      <img height="15px" src="/edit.png" alt="edit" align="right" />
                    </span>
                    {taskToBeAssign.index === index && (
                      <Modal handleModal={() => setTaskToBeAssign("")}>
                        <TmAssignTask
                          task={task}
                          projectId={projectId}
                          limit={limit}
                          currentPage={currentPage}
                        />
                      </Modal>
                    )}
                  </td>
                  <td>{task?.estimate_hours}</td>
                  <td>{task?.deadline}</td>
                  <td>
                    <div>
                      <Button1 onClick={() => setSubTaskToBeAdded({ task: task, index: index })}>
                        Add Subtask
                      </Button1>
                      {subTaskToBeAdded.index === index && (
                        <Modal handleModal={() => setSubTaskToBeAdded("")}>
                          <TmAddSubTask
                            task={subTaskToBeAdded.task}
                            projectId={projectId}
                            limit={limit}
                            currentPage={currentPage}
                          />
                        </Modal>
                      )}
                    </div>
                  </td>
                  {projectDetails?.columns?.map((column, index1) => {
                    // check for a value for current column
                    const columnIndex = task.column_values.findIndex(
                      (item) => item.column.cuid === column.cuid
                    );
                    const columnValue =
                      columnIndex !== -1 ? task.column_values[columnIndex].value.value : null;
                    return (
                      !column.is_deleted && (
                        <td key={column?.cuid + index1}>
                          <span>{columnValue ? columnValue : ""}</span>
                          <span
                            align="right"
                            onClick={() =>
                              setColumnValueToBeUpdated({
                                column: column, // all data of column
                                index: index, // current index
                                value: columnValue, // current column value
                              })
                            }
                            className="cd-edit-tag-icon"
                          >
                            <img height="15px" src="/edit.png" alt="edit" align="right" />
                          </span>
                        </td>
                      )
                    );
                  })}
                  {/* delete custom column */}
                  {columnToBeDeleted.index === index && (
                    <Modal handleModal={() => setColumnToBeDeleted(false)}>
                      <TmDeleteCustomColumn
                        columnId={columnToBeDeleted?.columnId}
                        handleModal={() => setColumnToBeDeleted(false)}
                        projectId={projectId}
                      />
                    </Modal>
                  )}
                  {columnValueToBeUpdated.index === index && (
                    <Modal handleModal={() => setColumnValueToBeUpdated(false)}>
                      <TmUpdateCustomColumnValues
                        column={columnValueToBeUpdated?.column}
                        task={task}
                        projectId={projectId}
                        value={columnValueToBeUpdated.value}
                        taskUpdated={() => setTaskUpdated(!taskUpdated)}
                      />
                    </Modal>
                  )}
                </tr>
                {task?.subtask.length > 0 && showSubTask?.taskid === task?.tuid && (
                  <tr className="cd-subtask-row">
                    <td>Subtasks</td>
                  </tr>
                )}
                {task?.subtask.length > 0 &&
                  showSubTask?.taskid === task?.tuid &&
                  task?.subtask.map((subtask, index1) => (
                    <tr
                      key={subtask?.tuid + index1}
                      className={`cd-subtask-row ${showSubTask?.taskid ? "expanded" : "not-expanded"}`}
                    >
                      <td>
                        <span>{subtask?.title}</span>
                        <span
                          onClick={() =>
                            setTaskToBeUpdate({ task: subtask, index1: subtask?.tuid })
                          }
                          className="cd-edit-tag-icon"
                        >
                          <img height="15px" src="/edit.png" alt="edit" align="right" />
                        </span>
                        {taskToBeUpdate.index1 === subtask?.tuid && (
                          <Modal handleModal={() => setTaskToBeUpdate("")}>
                            <TmUpdateTask
                              task={subtask}
                              projectId={projectId}
                              limit={limit}
                              currentPage={currentPage}
                            />
                          </Modal>
                        )}
                      </td>
                      <td>
                        <div className="cd-view-comments" align="center">
                          <span
                            className="cd-comment-icon"
                            onClick={() =>
                              setCommentToBeAdded({ task: subtask, index1: subtask.tuid })
                            }
                          >
                            <img src="/comment.png" />
                          </span>
                        </div>
                        {commentToBeAdded?.index1 === subtask.tuid && (
                          <Modal handleModal={() => setCommentToBeAdded("")}>
                            <TmAddTaskComment taskId={commentToBeAdded.task.tuid} />
                          </Modal>
                        )}
                      </td>
                      <td></td>
                      <td>
                        {subtask.tags_assigned.map((tag, index) => (
                          <span
                            className="cd-tag-title"
                            key={tag?.tuid + index}
                            style={{ backgroundColor: tag.tag.color, marginRight: "5px" }}
                          >
                            {tag.tag.title}{" "}
                            <span
                              onClick={() =>
                                removeTag({ task_id: subtask.tuid, tag_id: tag.tag.tuid })
                              }
                              className="cd-cross-mark"
                            >
                              &times;
                            </span>
                          </span>
                        ))}
                        <span
                          onClick={() => setTaskToAssignTag({ task: subtask, index1: index1 })}
                          className="cd-edit-tag-icon"
                          align="center"
                        >
                          <img height="15px" src="/edit.png" alt="edit" align="center" />
                        </span>
                        {taskToAssignTag?.index1 === index1 && (
                          <Modal handleModal={() => setTaskToAssignTag("")}>
                            <TmAssignTag
                              task={taskToAssignTag.task}
                              projectId={projectId}
                              limit={limit}
                              currentPage={currentPage}
                            />
                          </Modal>
                        )}
                      </td>
                      <td>
                        {subtask.assigned_to.map((assignee, index) => (
                          <div key={index}>{assignee.user.username}</div>
                        ))}
                        <span
                          onClick={() => setTaskToBeAssign({ task: subtask, index1: subtask.tuid })}
                          className="cd-edit-tag-icon"
                        >
                          <img height="15px" src="/edit.png" alt="edit" align="right" />
                        </span>
                        {taskToBeAssign.index1 === subtask.tuid && (
                          <Modal handleModal={() => setTaskToBeAssign("")}>
                            <TmAssignTask
                              task={subtask}
                              projectId={projectId}
                              limit={limit}
                              currentPage={currentPage}
                            />
                          </Modal>
                        )}
                      </td>
                      <td>{subtask?.estimate_hours}</td>
                      <td>{subtask?.deadline}</td>
                      <td></td>
                      {projectDetails?.columns?.map((column, index1) => {
                        // check for a value for current column
                        const columnIndex = subtask.column_values.findIndex(
                          (item) => item.column.cuid === column.cuid
                        );
                        const columnValue =
                          columnIndex !== -1
                            ? subtask.column_values[columnIndex].value.value
                            : null;
                        return (
                          !column.is_deleted && (
                            <td key={index1}>
                              <span>{columnValue ? columnValue : ""}</span>
                              <span
                                align="right"
                                onClick={() =>
                                  setColumnValueToBeUpdated({
                                    column: column, // all data of column
                                    index: subtask?.tuid, // current index
                                    value: columnValue, // current column value
                                  })
                                }
                                className="cd-edit-tag-icon"
                              >
                                <img height="15px" src="/edit.png" alt="edit" align="right" />
                              </span>
                            </td>
                          )
                        );
                      })}
                      {/* delete custom column */}
                      {columnToBeDeleted.index === subtask?.tuid && (
                        <Modal handleModal={() => setColumnToBeDeleted(false)}>
                          <TmDeleteCustomColumn
                            columnId={columnToBeDeleted?.columnId}
                            handleModal={() => setColumnToBeDeleted(false)}
                            projectId={projectId}
                          />
                        </Modal>
                      )}
                      {columnValueToBeUpdated.index === subtask?.tuid && (
                        <Modal handleModal={() => setColumnValueToBeUpdated(false)}>
                          <TmUpdateCustomColumnValues
                            column={columnValueToBeUpdated?.column}
                            task={subtask}
                            projectId={projectId}
                            value={columnValueToBeUpdated.value}
                            taskUpdated={() => setTaskUpdated(!taskUpdated)}
                          />
                        </Modal>
                      )}
                    </tr>
                  ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="cd-success-msg">No tasks!</div>
      )}
      {allTasks?.total !== 0 && (
        <>
          <Pagination
            onClickPrev={handlePrev}
            onClickNext={handleNext}
            currentPage={currentPage}
          ></Pagination>
          <GoToPage
            inputPage={inputPage}
            onClick={handleInputPage}
            onChange={(e) => setInputPage(e.target.value)}
          ></GoToPage>
        </>
      )}
    </div>
  );
};

export default TmAllTasks;
