import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAllSubscription } from "../../redux/slice/subscriptionSlice";
import Heading2 from "../../components/headings/Heading2";
import { SUBSCRIPTION_SEARCH } from "../../api";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Pagination from "../../components/pagination/Pagination";
import GoToPage from "../../components/pagination/GoToPage";
import Search from "../../components/search/Search";
import { scrollToTop } from "../../redux/handleUser";
import { currencyMapping } from "../../components/commoncomps/ChangeCurrency";
import axiosFetch from "../../axiosConfig";

const AllSubscriptions = ({ setProgress }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const page = new URLSearchParams(window.location.search).get("page");
  const [loader, setLoader] = useState(true);
  const [limit] = useState(10);
  const [currentPage, setCurrentPage] = useState(() => {
    return page ? parseInt(page) : 1;
  });
  const [inputPage, setInputPage] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchResponse, setSearchResponse] = useState();

  // constant to use useTranslation() hook
  const { t } = useTranslation();

  // getting all the subscriptions from subscription slice
  const {
    getAllSubscription: { allSubscriptions },
  } = useSelector((state) => state.subscription);

  // getting the currency from localstorage
  const currency = localStorage.getItem("currencyCMS");

  //to get all subscriptions on page render
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    dispatch(
      getAllSubscription({
        limit: limit,
        offset: (currentPage - 1) * limit,
      })
    ).then(() => {
      setProgress(100);
      setLoader(false);
      scrollToTop();
    });
  }, [currentPage]);

  // to search the subscription
  useEffect(() => {
    if (searchKeyword.length > 3) {
      const searchSubscription = async () => {
        try {
          const resp = await axiosFetch.get(SUBSCRIPTION_SEARCH + `?search=${searchKeyword}`);
          setSearchResponse(resp.data);
        } catch (error) {
          setSearchResponse(error.resp);
        }
      };
      searchSubscription();
    }
  }, [searchKeyword]);

  // functions for handling pagination
  const handlePrev = () => {
    let tempCurrentPage = currentPage;
    if (currentPage > 1) {
      tempCurrentPage -= 1;
      setCurrentPage(parseInt(tempCurrentPage));
      navigate(`?page=${tempCurrentPage}`);
      if (tempCurrentPage === 1) {
        navigate(window.location.pathname);
        return;
      }
    }
  };

  const handleNext = () => {
    let tempCurrentPage = currentPage;
    if (tempCurrentPage >= Math.ceil(allSubscriptions?.total / limit)) return;
    tempCurrentPage += 1;
    setCurrentPage(parseInt(tempCurrentPage));
    navigate(`?page=${tempCurrentPage}`);
  };

  const handleInputPage = () => {
    if (inputPage < 0 || inputPage === "" || inputPage > Math.ceil(allSubscriptions?.total / limit))
      return;
    setCurrentPage(parseInt(inputPage));
    navigate(`?page=${inputPage}`);
  };

  return (
    <>
      <DashboardWrapper>
        <Search
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          searchResponse={searchResponse?.result}
          placeholder="Enter search keyword"
        ></Search>
        <Heading2>{t("subscription")}</Heading2>
        {allSubscriptions === undefined && <div className="cd-err-msg">Internal Server Error</div>}
        <div className={`cd-subscriptins-list-container ${loader ? "not-appeared" : "appeared"}`}>
          {/* code block to show the subscription details */}
          {allSubscriptions?.subscriptions?.map((data, index) =>
            data?.is_deleted === false ? (
              <div key={index} className="cd-subscription-list-element">
                <div>
                  {t("name")} : {data?.title}
                </div>
                <div>
                  {t("description")} : {data?.description}
                </div>
                {/*  */}
                <div>
                  {t("price")}({currency}) :{" "}
                  <strike className="cd-err-msg">
                    {currencyMapping[currency].symbol}
                    {data?.[currencyMapping[currency].priceKey]}
                  </strike>{" "}
                  <span className="cd-success-msg">
                    <b>
                      {currencyMapping[currency].symbol}
                      {data?.[currencyMapping[currency].salePriceKey]}
                    </b>
                  </span>
                </div>
                <div>
                  {t("validity")} (in days) : {data?.validity}
                </div>
                <div>
                  <Link className="cd-common-anchor-tag" to={`/subscription-details/${data?.suid}`}>
                    {t("details")}
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )
          )}
        </div>
        <Pagination
          currentPage={currentPage}
          onClickPrev={handlePrev}
          onClickNext={handleNext}
        ></Pagination>
        <GoToPage
          inputPage={inputPage}
          onClick={handleInputPage}
          onChange={(e) => setInputPage(e.target.value)}
        ></GoToPage>
      </DashboardWrapper>
    </>
  );
};

export default AllSubscriptions;
