import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAllOrgUsers } from "../../redux/slice/organisationsSlice";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import axiosFetch from "../../axiosConfig";
import { TmADD_PROJECT_USER } from "../../api";
import Loader2 from "../../components/loaders/Loader2";

const TmAddProjectUser = ({ projectId, userAdded }) => {
  const [selectedUser, setSelectedUser] = useState();
  const [addProjectUserResponse, setAddProjectUserResponse] = useState();
  const [loader, setLoader] = useState(false);

  // constant to use useDispatch() hook
  const dispatch = useDispatch();
  const { t } = useTranslation(); // const to use this hook

  // constant to disable the Add button
  const isDisabled = selectedUser ? false : true;

  // get all organisation users from redux state
  const {
    getAllOrgUsers: { allOrgUsers },
  } = useSelector((state) => state.organisation);
  // call the API to get all the org users
  useEffect(() => {
    dispatch(getAllOrgUsers()).then(() => {});
  }, []);

  // code block to call the API of add-project-user
  let addProjectUserPayload = {
    project_uid: projectId,
    user_uuid: selectedUser,
  };
  const addProjectUser = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(TmADD_PROJECT_USER, addProjectUserPayload);
      setLoader(false);
      setAddProjectUserResponse(resp);
      // this line will refresh the data of project user list
      if (resp.status === 201) userAdded();
    } catch (error) {
      setLoader(false);
      setAddProjectUserResponse(error.response);
    }
  };

  return (
    <>
      <Heading2 align="center">{t("addUser")} </Heading2>
      <div className="cd-input-container">
        <select
          onChange={(e) => setSelectedUser(e.target.value)}
          name="selectProjectUser"
          id="selectProjectUser"
          className="cd-input-field"
        >
          <option value="">{t("select")} </option>
          {allOrgUsers?.users &&
            allOrgUsers?.users.map((user, index) => {
              return (
                <option key={index} value={user?.user?.uuid}>
                  {user?.user?.username}
                </option>
              );
            })}
        </select>
      </div>
      <Button1 onClick={addProjectUser} disabled={isDisabled}>
        {t("add")} {loader && <Loader2 />}
      </Button1>
      {addProjectUserResponse?.status == 201 && (
        <div className="cd-success-msg">{t("userAdded")} !</div>
      )}
      {addProjectUserResponse?.data?.detail && (
        <div className="cd-err-msg">{addProjectUserResponse?.data?.detail[0]?.msg}</div>
      )}
      {addProjectUserResponse?.data?.detail?.detail && (
        <div className="cd-err-msg">{addProjectUserResponse?.data?.detail?.detail[0]?.msg}</div>
      )}
    </>
  );
};

export default TmAddProjectUser;
