import { React, useState } from "react";
import { useTranslation } from "react-i18next";

import axiosFetch from "../../axiosConfig";
import { TmPROJECT_UPDATE } from "../../api";
import Label1 from "../../components/labels/Label1";
import InputField from "../../components/inputs/InputField";
import TextArea from "../../components/inputs/TextArea";
import Loader2 from "../../components/loaders/Loader2";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";

const TmUpdateProject = ({ projectDetails, puid }) => {
  const [loader2, setLoader2] = useState(false);
  const { t } = useTranslation(); // constant to use this hook
  const [projectTitle, setProjectTitle] = useState(
    projectDetails.title ? projectDetails.title : ""
  );
  const [projectDescription, setProjectDescription] = useState(
    projectDetails.description ? projectDetails.description : ""
  );
  const [projectActivityStatus, setProjectActivityStatus] = useState(
    projectDetails.is_active ? projectDetails.is_active : ""
  );
  const [updateProjectResponse, setUpdateProjectResponse] = useState({});
  const [showChangeStatus, setShowChangeStatus] = useState(false);

  // call the API of update project
  let updateProjectPayload = {
    project_id: puid,
    title: projectTitle,
    description: projectDescription,
    is_active: projectActivityStatus,
    is_deleted: false,
  };
  const updateProject = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(TmPROJECT_UPDATE, updateProjectPayload);
      setLoader2(false);
      setUpdateProjectResponse(resp.data);
    } catch (error) {
      setLoader2(false);
      setUpdateProjectResponse(error.response);
    }
  };

  return (
    <>
      <Heading2 align="center">{t("updateProject")} </Heading2>
      <div className="cd-input-container">
        <Label1>{t("name")} :</Label1>
        <InputField
          placeholder={t("placeholderProjectTitle")}
          name="cd_project_title"
          value={projectTitle}
          onChange={(e) => setProjectTitle(e.target.value)}
        ></InputField>
      </div>
      <div className="cd-input-container">
        <Label1>{t("description")} :</Label1>
        <TextArea
          placeholder={t("placeholderProjectDesc")}
          onChange={(e) => setProjectDescription(e.target.value)}
          value={projectDescription}
          rows="5"
          cols="30"
        ></TextArea>
      </div>
      <Button1 onClick={() => setShowChangeStatus(!showChangeStatus)}>{t("activeStatus")} </Button1>
      {showChangeStatus && (
        <div>
          <select
            name="activityStatus"
            id="activityStatus"
            className="cd-input-field"
            value={projectActivityStatus}
            onChange={(e) => setProjectActivityStatus(e.target.value)}
          >
            <option value={true}>{t("active")} </option>
            <option value={false}>{t("deactivate")} </option>
          </select>
        </div>
      )}
      <div className="cd-msg-container">
        {updateProjectResponse?.puid && (
          <div className="cd-success-msg">{t("projectUpdatedMsg")} !</div>
        )}
        {updateProjectResponse?.data?.detail && (
          <div className="cd-err-msg">{updateProjectResponse?.data?.detail[0].msg}</div>
        )}
        {updateProjectResponse?.data?.detail?.detail && (
          <div className="cd-err-msg">{updateProjectResponse?.data?.detail?.detail[0].msg}</div>
        )}
      </div>
      <Button1 onClick={updateProject}>
        {t("update")} {loader2 && <Loader2 />}
      </Button1>
    </>
  );
};

export default TmUpdateProject;
