import { React, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Heading2 from "../../components/headings/Heading2";
import OrganisationWrapper from "../../components/wrappers/OrganisationWrapper";
import Button1 from "../../components/buttons/Button1";
import Modal from "../../components/modal/Modal";
import TmCreateTask from "./TmCreateTask";
import TmCreateCustomColumn from "./TmCreateCustomColumn";
import TmAllTasks from "./TmAllTasks";
import TmUpdateProject from "./TmUpdateProject";
import TmCreateTaskGroup from "./TmCreateTaskGroup";
import { getProjectDetails } from "../../redux/slice/taskManagementSlice";

const TmProjectDetails = ({ setProgress }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(); // const to use this hook
  const { puid, slug } = useParams(); // get project id and org slug
  const path = window.location.pathname;
  localStorage.setItem("last-task", path);
  // const [projectDetails, setProjectDetails] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openCreateTaskModal, setOpenCreateTaskModal] = useState(false);
  const [openCreateColumnModal, setOpenCreateColumnModal] = useState(false);
  const [openCreateTaskGroupModal, setOpenCreateTaskGroupModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [newTaskCreated, setNewTaskCreated] = useState(false);
  const [customColumnCreated, setCustomColumnCreated] = useState(false);
  const [customColumnDeleted, setCustomColumnDeleted] = useState(false);
  const [customColumnUpdated, setCustomColumnUpdated] = useState(false);

  // dispatch action for project details from taskManagement state of redux
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    dispatch(
      getProjectDetails({
        projectId: puid,
      })
    ).then(() => {
      setProgress(100);
      setLoader(false);
    });
  }, []);

  // getting project details from taskManagement state of redux
  const {
    getProjectDetails: { projectDetails },
  } = useSelector((state) => state.taskManagement);

  return (
    <OrganisationWrapper>
      <Heading2>{projectDetails?.title}</Heading2>
      <div className={loader ? "not-appeared" : "appeared"}>
        <div className="cd-project-quick-link-container clearfix">
          <div className="cd-project-option">
            <Link className="cd-common-anchor-tag" to={`/${slug}/project-users/${puid}`}>
              {t("projectUsers")}
            </Link>
          </div>
          <div className="cd-project-option">
            <Link className="cd-common-anchor-tag" to={`/${slug}/tags/${puid}`}>
              {t("tags")}
            </Link>
          </div>
          <div className="cd-project-option">
            <Link className="cd-common-anchor-tag" to={`/${slug}/task-groups/${puid}`}>
              {t("taskGroups")}
            </Link>
          </div>
        </div>
        <div className="cd-project-options-container clearfix">
          {/* project option added to update project */}
          <div className="cd-project-option">
            <Button1 onClick={() => setOpenModal(!openModal)}>{t("update")} </Button1>
          </div>
          {openModal && (
            <Modal handleModal={() => setOpenModal(!openModal)}>
              <TmUpdateProject projectDetails={projectDetails} puid={puid} />
            </Modal>
          )}

          {/* project option added to create new task in project */}
          <div className="cd-project-option">
            <Button1 onClick={() => setOpenCreateTaskModal(!openCreateTaskModal)}>
              {t("createNewTask")}
            </Button1>
          </div>
          {openCreateTaskModal && (
            <Modal handleModal={() => setOpenCreateTaskModal(!openCreateTaskModal)}>
              <TmCreateTask
                taskCreated={() => setNewTaskCreated(!newTaskCreated)}
                projectId={puid}
              />
            </Modal>
          )}

          {/* project option added to create custom column in project */}
          <div className="cd-project-option">
            <Button1 onClick={() => setOpenCreateColumnModal(!openCreateColumnModal)}>
              {t("createCustomColumn")}
            </Button1>
          </div>
          {openCreateColumnModal && (
            <Modal handleModal={() => setOpenCreateColumnModal(!openCreateColumnModal)}>
              <TmCreateCustomColumn
                customColumnCreated={() => setCustomColumnCreated(!customColumnCreated)}
                projectId={puid}
              />
            </Modal>
          )}

          {/* project option added to create task group in project */}
          <div className="cd-project-option">
            <Button1 onClick={() => setOpenCreateTaskGroupModal(!openCreateTaskGroupModal)}>
              {t("createTaskGroup")}
            </Button1>
          </div>
          {openCreateTaskGroupModal && (
            <Modal handleModal={() => setOpenCreateTaskGroupModal(!openCreateTaskGroupModal)}>
              <TmCreateTaskGroup projectId={puid} />
            </Modal>
          )}
        </div>
        <TmAllTasks
          setProgress={setProgress}
          newTaskCreated={newTaskCreated}
          projectId={puid}
          progress={setProgress}
          // customColumns={projectDetails.columns}
          customColumnCreated={customColumnCreated}
          customColumnDeleted={() => setCustomColumnDeleted(!customColumnDeleted)}
          customColumnUpdated={() => setCustomColumnUpdated(!customColumnUpdated)}
        />
      </div>
    </OrganisationWrapper>
  );
};

export default TmProjectDetails;
