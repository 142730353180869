import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAllTaskGroups } from "../../redux/slice/taskManagementSlice";
import { TmCREATE_TASK } from "../../api";
import Heading2 from "../../components/headings/Heading2";
import Label1 from "../../components/labels/Label1";
import InputField from "../../components/inputs/InputField";
import InputDate from "../../components/inputs/InputDate";
import InputNumber from "../../components/inputs/InputNumber";
import Button1 from "../../components/buttons/Button1";
import Loader2 from "../../components/loaders/Loader2";
import axiosFetch from "../../axiosConfig";
import Modal from "../../components/modal/Modal";
import TmCreateTaskGroup from "./TmCreateTaskGroup";

const TmCreateTask = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(); // constant to use this hook
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [taskGroupId, setTaskGroupId] = useState("");
  const [estimatedHours, setEstimatedHours] = useState("");
  const [taskDeadline, setTaskDeadline] = useState(new Date().toISOString());
  const [createTaskResponse, setCreateTaskResponse] = useState({});
  const [openCreateTaskGroupModal, setOpenCreateTaskGroupModal] = useState(false);
  const [taskGroupCreate, setTaskGroupCreate] = useState(false);
  const [loader, setLoader] = useState(false);

  // to get all task groups
  useEffect(() => {
    setLoader(true);
    dispatch(
      getAllTaskGroups({
        projectId: props.projectId,
      })
    ).then(() => {
      setLoader(false);
    });
  }, [taskGroupCreate]);

  // getting all task grops from taskManagement state
  const {
    getTaskGroups: { allTaskGroups },
  } = useSelector((state) => state.taskManagement);

  // calling the API of create task
  let createTaskPayload = {
    title: taskTitle,
    description: taskDescription,
    project_id: props.projectId,
    group_id: taskGroupId,
    estimate_hours: estimatedHours,
    deadline_date: taskDeadline,
  };
  // console.log(createTaskPayload);
  const createTask = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(TmCREATE_TASK, createTaskPayload);
      setCreateTaskResponse(resp.data);
      setLoader(false);
      if (resp.status === 201) props.taskCreated();
    } catch (error) {
      setCreateTaskResponse(error.response);
      setLoader(false);
    }
  };

  // constant to disable the create button
  const isDisabled =
    taskTitle.length === 0 || taskDescription.length === 0 || !props.projectId ? true : false;

  return (
    <>
      <Heading2 align="center">{t("createNewTask")} </Heading2>
      <div>
        <div className="cd-input-container">
          <Label1>{t("name")} :</Label1>
          <InputField
            placeholder={t("placeholderTaskTitle")}
            value={taskTitle}
            name="cd_task_title"
            onChange={(e) => setTaskTitle(e.target.value)}
          ></InputField>
        </div>
        <div className="cd-input-container">
          <Label1>{t("description")} :</Label1>
          <InputField
            placeholder={t("placeholderTaskDesc")}
            name="cd_task_description"
            value={taskDescription}
            onChange={(e) => setTaskDescription(e.target.value)}
          ></InputField>
        </div>
        <div className="cd-input-container cd-add-task-group-container clearfix">
          <div className="cd-task-group-input">
            <Label1>{t("taskGroups")} :</Label1>
            <select
              name="projectGroup"
              id="projectGroup"
              className="cd-input-field"
              value={taskGroupId}
              onChange={(e) => setTaskGroupId(e.target.value)}
            >
              <option value="">{t("select")} </option>
              {allTaskGroups?.task_groups?.map((task, index) => (
                <option key={index} value={task.guid}>
                  {task.title}
                </option>
              ))}
            </select>
          </div>
          {allTaskGroups?.total === 0 && (
            <>
              <div className="cd-add-task-group-button">
                <Button1 onClick={() => setOpenCreateTaskGroupModal(true)}>
                  {t("createTaskGroup")}{" "}
                </Button1>
              </div>
              {openCreateTaskGroupModal && (
                <Modal handleModal={() => setOpenCreateTaskGroupModal(false)}>
                  <TmCreateTaskGroup
                    projectId={props.projectId}
                    taskGroupCreate={() => setTaskGroupCreate(!taskGroupCreate)}
                  />
                </Modal>
              )}
            </>
          )}
        </div>
        <div className="cd-input-container">
          <Label1>{t("estimatedHours")} :</Label1>
          <InputNumber
            placeholder={t("placeholderEstimatedHours")}
            name="cd_task_hours"
            min={0}
            value={estimatedHours}
            onChange={(e) => setEstimatedHours(e.target.value)}
          ></InputNumber>
        </div>
        <div className="cd-input-container">
          <Label1>{t("deadline")} :</Label1>
          <InputDate
            placeholder="Enter task deadline"
            name="cd_task_deadline"
            value={taskDeadline}
            onChange={(e) => {
              setTaskDeadline(e.target.value);
            }}
          ></InputDate>
        </div>
        {/* Showing the API response */}
        {createTaskResponse?.title && <div className="cd-success-msg">{t("taskCreatedMsg")} !</div>}
        {createTaskResponse?.data?.detail && (
          <div className="cd-err-msg">{createTaskResponse?.data?.detail[0]?.msg}</div>
        )}
        {createTaskResponse?.data?.detail?.detail && (
          <div className="cd-err-msg">{createTaskResponse?.data?.detail?.detail[0]?.msg}</div>
        )}
        <Button1 onClick={createTask} disabled={isDisabled}>
          {t("create")} {loader && <Loader2 />}
        </Button1>
      </div>
    </>
  );
};

export default TmCreateTask;
