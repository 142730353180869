import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SUBSCRIPTION_DETAILS } from "../../api";
import axiosFetch from "../../axiosConfig";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import { currencyMapping } from "../../components/commoncomps/ChangeCurrency";

const SubscriptionDetails = ({ setProgress }) => {
  // to get the parameters we passed in url
  const { suid } = useParams();

  // constant to use useTranslation() hook
  const { t } = useTranslation();

  // getting currency from localStorage
  let currency = localStorage.getItem("currencyCMS");
  // console.log(currency);

  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [loader, setLoader] = useState(true);
  // console.log(paymentGateway);

  //to get current subscription detail
  useEffect(() => {
    const getSubscriptionDetails = async () => {
      setLoader(true);
      setProgress(40);
      try {
        const resp = await axiosFetch.get(SUBSCRIPTION_DETAILS + suid);
        if (resp.status === 200) {
          setSubscriptionDetails(resp?.data);
          setLoader(false);
          setProgress(100);
        }
        // console.log(resp);
      } catch (error) {
        setSubscriptionDetails(error?.response);
        setLoader(false);
        setProgress(100);
        // console.log(error?.response);
      }
    };
    getSubscriptionDetails();
  }, [suid]);

  return (
    <div>
      <DashboardWrapper>
        <Heading2>{t("subscriptionDetail")}</Heading2>
        {/* code block to show the loader */}
        <div className={`cd-subscription-detail-container ${loader ? "not-appeared" : "appeared"}`}>
          <div>
            {t("name")} : {subscriptionDetails?.title}
          </div>
          <div>
            {t("description")} : {subscriptionDetails?.description}
          </div>
          <div>
            {t("price")}({currency}) :{" "}
            <strike className="cd-err-msg">
              {currencyMapping?.[currency].symbol}
              {subscriptionDetails?.[currencyMapping[currency].priceKey]}
            </strike>{" "}
            <span className="cd-success-msg">
              <b>
                {currencyMapping?.[currency].symbol}
                {subscriptionDetails?.[currencyMapping[currency].salePriceKey]}
              </b>
            </span>
          </div>
          <div>
            {t("validity")} (in days) : {subscriptionDetails?.validity}
          </div>
          <div>
            <Link className="cd-common-anchor-tag" to={`/checkout/${suid}`}>
              <Button1 className="cd-btn">{t("buy")}</Button1>
            </Link>
          </div>
        </div>
      </DashboardWrapper>
    </div>
  );
};

export default SubscriptionDetails;
