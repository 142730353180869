import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { userLogout } from "../../redux/slice/userSlice";

const Navbar = (props) => {
  const dispatch = useDispatch();
  const { slug } = useParams();

  // constant to use useTranslation() hook
  const { t } = useTranslation();

  const [showControl, setShowControl] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const profilePhoto = user?.profile_photo;

  //show the user controls edit profile and logout
  const showUserControl = () => {
    if (showControl) {
      setShowControl(false);
      return;
    }
    setShowControl(true);
  };

  //to logout the current user
  const logOutUser = async () => {
    dispatch(userLogout()).then(() => {
      window.location.replace("/login");
      // navigate("/login");
    });
  };
  return (
    <div className="cd_navbar_container clearfix">
      <div className="cd_navbar_wrapper clearfix">
        <ul className="cd_navbar_element_group clearfix">
          <li className="cd_navbar_element">
            <Link className="cd-common-anchor-tag" to="/all-subscriptions">
              {t("subscription")}
            </Link>
          </li>
          <li className="cd_navbar_element">
            <Link className="cd-common-anchor-tag" to="/all-organisations">
              {t("organization")}
            </Link>
          </li>
        </ul>
        <div className="cd_navbar_user_profile_controls clearfix">
          <div className="cd-bell-icon-container cd_navbar_element">
            <Link to={`/${slug}/notifications`}>
              <img src="/bell-icon.png" alt="" className="cd-bell-icon" />
            </Link>
          </div>
          <div className="cd_user_control_section cd_navbar_element">
            <div>
              <button onClick={showUserControl} className="cd_user_control_btn">
                {profilePhoto ? (
                  <img
                    className="user_icon"
                    src={`https://codecms.codeholic.in/static/uploads/${profilePhoto}`}
                    height="30px"
                    alt=""
                  />
                ) : (
                  <img className="user_icon" src="/user-128.png" height="30px" alt="" />
                )}
              </button>
              <div>{user?.fullname ? user?.fullname : user?.username}</div>
            </div>
            {showControl && (
              <div className="cd_user_controls">
                <div>
                  <Link to="/view-profile" className="cd_user_control_element">
                    {t("profile")}
                  </Link>
                  <Link className="cd_user_control_element" to="/change-currency">
                    Currency
                  </Link>
                  <span onClick={logOutUser} className="cd_user_control_element">
                    {t("logout")}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
