import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/userSlice";
import subscriptionReducer from "./slice/subscriptionSlice";
import organisationReducer from "./slice/organisationsSlice";
import taskManagementReducer from "./slice/taskManagementSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    subscription: subscriptionReducer,
    organisation: organisationReducer,
    taskManagement: taskManagementReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false, // Disable serializability check
    });
  },
});
