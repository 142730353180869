export const BASE_URL = "https://codecms.codeholic.in";
// export const BASE_URL = "http://127.0.0.1:8000";
export const GET_TIMEZONES = "/frontend-user/timezones";
export const IMAGE_PATH_PREFIX = "https://codecms.codeholic.in/static/uploads/";

// authentication
export const REGISTER_ENDPOINT = "/frontend-user/register";
export const VERIFY_TOKEN = "/frontend-user/verify-token";
export const LOGIN_ENDPOINT = "/frontend-user/login";
export const LOGOUT_ENDPOINT = "/frontend-user/logout";
export const FORGOT_PASSWORD_EMAIL_TOKEN = "/frontend-user/send-token";
export const CHANGE_PASSWORD_ENDPOINT = "/frontend-user/create-password";

// user profile
export const GET_PROFILE_DETAIL = "/frontend-user/profile";
export const UPDATE_PROFILE = "/frontend-user/update-profile";
export const UPDATE_PROFILE_PHOTO = "/frontend-user/update-profile-photo";

// subscriptions
export const GET_ALL_SUBSCRIPTIONS = "/frontend-user/subscriptions";
export const SUBSCRIPTION_DETAILS = "/frontend-user/subscriptions/";
export const SUBSCRIPTION_SEARCH = "/frontend-user/search-subscription";
export const GET_ALL_TAX_REGIONS = "/frontend-user/list-region-data";

// organisations
export const ORGANISATION_GET_ALL = "/organization/get-all-organizations";
export const ORGANISATION_DETAILS = "/organization/organizations-details";
export const ORGANISATION_CREATE = "/organization/create-organization";
export const ORGANISATION_UPDATE = "/organization/update-organization";
export const ORGANISATION_UPDATE_ICON = "/organization/upload-organization-photo";
export const ORGANIZATION_UPDATE_DRIVE_TOKEN = "/organization/update-organization-drive-token";
// export const ORGANISATION_USERS = "/organization/users";
export const ORGANISATION_USERS = "/organization/organization-users";
export const ORGANISATION_UPDATE_USER_STATUS = "/organization/update-organization-user-status";
export const ORGANISATION_DELETE_USER = "/organization/delete-user-from-organization/";
export const ORGANISATION_USER_DETAILS = "/organization/users";
export const ORGANISATION_INVITE_USER = "/organization/invite-user";
export const ORGANISATION_SEARCH_USER = "/organization/search-frontenduser-for-invite";
export const ORGANISATION_GET_ROLES = "/organization/roles";
export const ORGANISATION_ASSIGN_ROLE = "/organization/assign-role";
export const ORGANISATION_GET_PERMISSIONS = "/organization/permissions";
export const ORGANISATION_CREATE_ROLE = "/organization/create-role";
export const ORGANISATION_UPDATE_ROLE = "/organization/update-role";
export const ORGANISATION_ASSIGN_USER_PERMISSION = "/organization/assign-user-permission";
export const ORGANISATION_VERIFY_USER_INVITATION = "/organization/verify-invitation";
export const ORGANIZATION_FILE_PREVIEW = "/organization/file/";

// task management
export const TmCREATE_PROJECT = "/task-management/create-project";
export const TmPROJECTS = "/task-management/projects";
export const TmPROJECT_DETAILS = "/task-management/project";
export const TmPROJECT_UPDATE = "/task-management/update-project";
export const TmPROJECT_USERS = "/task-management/get-project-users";
export const TmADD_PROJECT_USER = "/task-management/add-project-users";
export const TmREMOVE_PROJECT_USER = "/task-management/remove-project-user";
export const TmUPDATE_PROJECT_USER = "/task-management/update-project-user";

export const TmPROJECT_PERMISSIONS = "/task-management/get-project-permissions";
export const TmPROJECT_USER_PERMISSIONS = "/task-management/get-project-user-permissions/";
export const TmASSIGN_PROJECT_PERMISSIONS = "/task-management/assign-project-user-permission";

export const TmCREATE_TASK = "/task-management/create-task";
export const TmGET_ALL_TASKS = "/task-management/tasks";
export const TmUPDATE_TASK = "/task-management/update-task";
export const TmUPLOAD_TASK_MEDIA = "/task-management/upload-task-media";
export const TmASSIGN_TASK = "/task-management/assign-task";
export const TmWITHDRAW_TASK = "/task-management/withdraw-task";

export const TmADD_CUSTOM_COLUMN = "/task-management/add-custom-column";
export const TmDELETE_CUSTOM_COLUMN = "/task-management/delete-custom-column";
export const TmADD_CUSTOM_COLUMN_EXP_VALUE = "/task-management/add-column-expected-values";
export const TmDELETE_CUSTOM_COLUMN_EXP_VALUE = "/task-management/delete-custom-column-value";
export const TmASSIGN_CUSTOM_COLUMN_VALUES = "/task-management/assign-custom-column-value";

export const TmCREATE_TASK_GROUP = "/task-management/create-task-group";
export const TmGET_ALL_TASK_GROUPS = "/task-management/get-all-task-group";
export const TmUPDATE_TASK_GROUP = "/task-management/update-task-group";

export const TmGET_ALL_COMMENTS_IN_TASK = "/task-management/get-task-comments";
export const TmADD_COMMENT_IN_TASK = "/task-management/add-comments";

export const TmCREATE_TAG = "/task-management/create-tag";
export const TmGET_TAGS = "/task-management/get-tags";
export const TmASSIGN_TAG = "/task-management/assign-tag";
export const TmREMOVE_TAG = "/task-management/remove-tag";
export const TmUPDATE_TAG = "/task-management/update-tag";
export const TmDELETE_TAG = "/task-management/delete-tag";

export const TmGET_NOTIFICATIONS = "/task-management/get-notifications";
export const TmDELETE_NOTIFICATION = "/task-management/delete-notification/";
export const TmDELETE_ALL_NOTIFICATION = "/task-management/delete-all-notifications";

// payment
export const CALCULATE_AMOUNT = "/frontend-user/calculate-amount";
export const CREATE_ORDER = "/frontend-user/create-order";
export const CREATE_TRANSACTIONS = "/frontend-user/create-transaction";
