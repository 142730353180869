import React, { useRef } from "react";
// import Button1 from '../buttons/Button1';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor1 = (props) => {
  const quillRef = useRef(null);
  // const [showLength, setShowLength] = useState(false);
  // console.log(props);

  // toolbar options
  const toolbarOptions = [
    ["bold", "italic", "underline"], //toggled buttons
    [{ list: "ordered" }, { list: "bullet" }], //list styles
    [{ align: [] }],
    ["link", "image"],
  ];

  // formates
  const allowedFormats = ["bold", "italic", "underline", "list", "header", "link"];

  // const length = quillRef?.current?.getEditor()?.getLength();
  return (
    <div>
      <ReactQuill
        theme="snow"
        value={props.value}
        onChange={props.onChange}
        modules={{ toolbar: toolbarOptions }}
        formats={allowedFormats}
        ref={quillRef}
      ></ReactQuill>
    </div>
  );
};

export default TextEditor1;
