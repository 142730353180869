import { React } from "react";
import { Link } from "react-router-dom";
import "../../pages/subscription/subscription.css";

import InputField from "../inputs/InputField";

/**
 * Component to search user, subscription,
 * @param {string} value - the input value
 * @param {function} onChange - the function used to set the input in a variable
 * @param {Array} searchResponse - the search result array
 * @returns {JSX.Element} - the JSX  element
 */

const Search = (props) => {
  return (
    <div className="cd-search-container">
      <div className="cd-search-keyword-input cd-input-container">
        {/* getting value and onChange from props */}
        <InputField
          value={props.value}
          name="cd_search_query"
          onChange={props.onChange}
          placeholder={props.placeholder}
        ></InputField>
      </div>

      {/* getting the search result from props and rendered here */}
      {props?.searchResponse?.length > 0 && props.value.length > 3 ? (
        <div className="cd-search-result-list">
          {props?.searchResponse?.map((subscription, index) => (
            <Link
              key={index}
              to={`/subscription-details/${subscription.suid}`}
              className="cd-common-anchor-tag"
            >
              <div key={index} className="cd-search-result-list-element">
                {subscription.title}
              </div>
            </Link>
          ))}
        </div>
      ) : (
        props.value.length > 3 &&
        props?.searchResponse && (
          <div className="cd-search-result-list cd-search-result-list-element">No result!</div>
        )
      )}
    </div>
  );
};

export default Search;
