import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getNotifications } from "../../redux/slice/taskManagementSlice";
import OrganisationWrapper from "../../components/wrappers/OrganisationWrapper";
import Heading2 from "../../components/headings/Heading2";
import { TmDELETE_ALL_NOTIFICATION, TmDELETE_NOTIFICATION } from "../../api";
import Button1 from "../../components/buttons/Button1";
import axiosFetch from "../../axiosConfig";
import Modal from "../../components/modal/Modal";
import Loader2 from "../../components/loaders/Loader2";
import "./notification.css";

const Notification = ({ setProgress }) => {
  const dispatch = useDispatch();
  const [deleteNotificationResponse, setDeleteNotificationResponse] = useState("");
  const [deleteAllNotificationResponse, setDeleteAllNotificationResponse] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loader2, setLoader2] = useState(false);

  // getting notification from redux state
  const {
    getNotifications: { allNotifications },
  } = useSelector((state) => state.taskManagement);

  // get all notifications
  useEffect(() => {
    setProgress(40);
    dispatch(getNotifications()).then(() => {
      setProgress(100);
    });
  }, [deleteNotificationResponse]);

  // function to call the API of Delete notification
  const deleteNotification = async (nuuid) => {
    try {
      const resp = await axiosFetch.delete(TmDELETE_NOTIFICATION + nuuid);
      setDeleteNotificationResponse(resp);
    } catch (error) {
      // console.log(error.response);
      setDeleteNotificationResponse(error.response);
    }
  };

  // function to call the API of delete all notifications
  const deleteAllNotifications = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.delete(TmDELETE_ALL_NOTIFICATION);
      setDeleteAllNotificationResponse(resp);
      setLoader2(false);
    } catch (error) {
      // console.log(error.response);
      setDeleteAllNotificationResponse(error.response);
      setLoader2(false);
    }
  };

  return (
    <OrganisationWrapper>
      <Heading2>Notifications</Heading2>

      <Button1 className="cd-delete-notification-tab" onClick={() => setShowDeleteModal(true)}>
        Clear all notification
      </Button1>

      {showDeleteModal && (
        <Modal
          handleModal={() => {
            setShowDeleteModal(false);
            setDeleteAllNotificationResponse("");
          }}
        >
          <div>Are you really want to delete all notifications?</div>
          {deleteAllNotificationResponse.status === "200" && (
            <div className="cd-success-msg">All notifications deleted!</div>
          )}
          {deleteAllNotificationResponse?.data?.detail && (
            <div className="cd-err-msg">{deleteAllNotificationResponse?.data?.detail[0]?.msg}</div>
          )}
          {deleteAllNotificationResponse?.data?.detail?.detail && (
            <div className="cd-err-msg">
              {deleteAllNotificationResponse?.data?.detail?.detail[0]?.msg}
            </div>
          )}
          <Button1 onClick={deleteAllNotifications}>Yes {loader2 && <Loader2 />}</Button1>
          <Button1 onClick={() => setShowDeleteModal(false)}>No</Button1>
        </Modal>
      )}

      {/* mapping notifications */}
      <div className="cd-notifications-container">
        {allNotifications && allNotifications?.count == 0 && (
          <div className="cd-success-msg">No notification!</div>
        )}
        {allNotifications &&
          allNotifications?.results &&
          allNotifications?.results.map((notification, index) => {
            return (
              <div key={index} className="cd-notification-list-element">
                <div className="cd-notification-type">
                  Type : {notification.type}{" "}
                  <span
                    onClick={() => deleteNotification(notification.nuuid)}
                    className="cd-cross-mark"
                  >
                    {" "}
                    &times;
                  </span>
                </div>
                <div className="cd-notification-title">{notification.title}</div>
                <div className="cd-notification-description">
                  Description : {notification.description}
                </div>
              </div>
            );
          })}
      </div>
    </OrganisationWrapper>
  );
};

export default Notification;
