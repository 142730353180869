import React, { useState } from "react";
import { FORGOT_PASSWORD_EMAIL_TOKEN } from "../../api";
import { useTranslation } from "react-i18next";
import InputEmail from "../../components/inputs/InputEmail";
import Button1 from "../../components/buttons/Button1";
import Loader from "../../components/loaders/Loader";
import Card1 from "../../components/cards/Card1";
import Heading2 from "../../components/headings/Heading2";
import axiosFetch from "../../axiosConfig";
import "../../App.css";

const SendToken = () => {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("");
  const [loader, setLoader] = useState(false);
  const isDisabled = email.length < 0 ? true : false;

  // constant to use useTranslation() hook
  const { t } = useTranslation();

  // function to call the send email token API
  const sendEmailToken = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.get(FORGOT_PASSWORD_EMAIL_TOKEN + `?email=${email}`, {});
      setResponse(resp);
      if (resp?.status === 200) setLoader(false);
    } catch (error) {
      // console.log(error.response)
      setResponse(error.response);
      if (error?.response?.data) setLoader(false);
    }
  };

  return (
    <Card1>
      <div>
        <Heading2>{t("sendVerificationToken")}</Heading2>
      </div>
      <div className="cd-input-container">
        <label htmlFor="" className="cd-input-label">
          {t("email")} :
        </label>
        <InputEmail
          className="cd-input-field"
          name="cd_send_token"
          placeholder={t("placeholderEmail")}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      {/* code block to show the success msg */}
      {response?.status === 200 && <div className="cd-success-msg">{t("tokenSentMsg")}</div>}

      {/* code block to show the error msg */}
      {response?.status !== 200 && (
        <div className="cd-err-msg">{response?.data?.detail[0]?.msg}</div>
      )}

      {/* code block to show the loader conditonally */}
      {loader && <Loader />}
      <div>
        <Button1 onClick={sendEmailToken} className="cd-btn" disabled={isDisabled}>
          {" "}
          {t("send")}{" "}
        </Button1>
      </div>
    </Card1>
  );
};

export default SendToken;
