import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { userLogin } from "../../redux/slice/userSlice";
import Card1 from "../../components/cards/Card1";
import Heading2 from "../../components/headings/Heading2";
import Label1 from "../../components/labels/Label1";
import Loader2 from "../../components/loaders/Loader2";
import InputField from "../../components/inputs/InputField";
import InputPassword from "../../components/inputs/InputPassword";
import Button1 from "../../components/buttons/Button1";

const Login = () => {
  // constant to use useTranslation() hook
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [usernameoremail, setUsernameoremail] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [password, setPassword] = useState("");
  const [userIp, setUserIp] = useState();
  const [loader, setLoader] = useState(false);

  const user = localStorage.getItem("user");

  // navigate user after 1 second
  useEffect(() => {
    setTimeout(() => {
      if (user) {
        navigate("/all-organisations");
      }
    }, 1000);
  }, []);

  // getting the ip of current user
  useEffect(() => {
    if (!user?.token) {
      const getTheIp = async () => {
        const resp = await axios.get("https://api.ipify.org/?format=json");
        setUserIp(resp.data.ip);
      };
      getTheIp();
    }
  }, []);

  // Getting the browser information
  const getBrowserName = () => {
    let browserInfo = navigator.userAgent;
    let browser;
    if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
      browser = "Opera";
    } else if (browserInfo.includes("Edg")) {
      browser = "Edge";
    } else if (browserInfo.includes("Chrome")) {
      browser = "Chrome";
    } else if (browserInfo.includes("Safari")) {
      browser = "Safari";
    } else if (browserInfo.includes("Firefox")) {
      browser = "Firefox";
    } else {
      browser = "unknown";
    }
    return browser;
  };
  const browser = getBrowserName();
  const system = navigator.platform;

  // payload to send with login API call
  const loginDetail = {
    username_or_email: usernameoremail,
    password: password,
    details: {
      ip_address: userIp,
      browser: browser,
      system: system,
    },
  };

  // redirect the user to selected organisation
  const lastOrganisation = localStorage.getItem("orgslug");
  const lastTask = localStorage.getItem("last-task");

  // dispatch the userLogin function on the click of Login button
  const loginUser = (e) => {
    e.preventDefault();
    setLoader(true);
    dispatch(userLogin(loginDetail)).then(() => {
      setTimeout(() => {
        if (lastTask) {
          window.location.replace(lastTask);
        } else if (lastOrganisation) {
          window.location.replace(`/${lastOrganisation}/view-organisation`);
        } else {
          window.location.replace("/all-organisations");
        }
        setLoader(false);
      }, 1000);
    });
  };

  //constant to disable the login button if user does'nt entered any value in inputs
  const isDisabled = usernameoremail.length < 4 || password.length < 4 ? true : false;

  // getting the user information from user state
  const {
    userLogin: { userData },
  } = useSelector((state) => state.user);
  // console.log(userData);

  // hide the password after 2 seconds
  let timeoutId;
  useEffect(() => {
    // Clear the previous timeout if it exists
    clearTimeout(timeoutId);

    if (passwordVisibility) {
      timeoutId = setTimeout(() => {
        setPasswordVisibility(false);
      }, 2000);
    }
    // function to clear the timeout when the component unmounts or when passwordVisibility changes
    return () => {
      clearTimeout(timeoutId);
    };
  }, [passwordVisibility]);
  return (
    <div>
      <Card1>
        <div>
          <Heading2> {t("login")} </Heading2>
        </div>
        <form onSubmit={loginUser}>
          <div className="cd-input-container">
            <Label1> {t("username") + "/" + t("email")} :</Label1>
            <InputField
              type="text"
              className="cd-input-field"
              name="login_username"
              placeholder={t("placeholderUsername")}
              onChange={(e) => setUsernameoremail(e.target.value)}
            ></InputField>
          </div>
          <div className="cd-input-container cd-eye-button-parent">
            <Label1>{t("password")}:</Label1>
            <InputPassword
              className=""
              name="login_password"
              type={passwordVisibility ? "text" : "password"}
              placeholder={t("placeholderPassword")}
              onChange={(e) => setPassword(e.target.value)}
            ></InputPassword>
            <img
              className="cd-eye-button-img"
              src="/eyeball.png"
              alt="eye-button"
              onClick={() => setPasswordVisibility(!passwordVisibility)}
            />
          </div>
          <div>
            <Button1 className="cd-btn" type="submit" disabled={isDisabled}>
              {" "}
              {t("login")} {loader && <Loader2 />}{" "}
            </Button1>
          </div>
          {/* code block the show the error got from API response */}
          {userData?.detail && (
            <div className="cd_msg_container">
              <div className="cd-err-msg">{userData?.detail?.detail[0].msg}</div>
            </div>
          )}
          {userData?.token && (
            <div className="cd_msg_container">
              <span className="cd-success-msg">Login success! Redirecting...</span>
            </div>
          )}
        </form>
        <div>
          {t("notHaveAcc")} <Link to="/sign-up"> {t("signUp")} </Link>
        </div>
        <div>
          <Link to="/send-email-verification-token"> {t("forgotPassword")} </Link>
        </div>
      </Card1>
    </div>
  );
};

export default Login;
