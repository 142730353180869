import React from "react";

const Heading2 = (props) => {
  return (
    <div className="cd_heading_2_container" align={props.align}>
      <h2>{props.children}</h2>
    </div>
  );
};

export default Heading2;
