import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, getTimeZones } from "../../redux/slice/userSlice";
import { UPDATE_PROFILE } from "../../api";
import { useTranslation } from "react-i18next";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Card1 from "../../components/cards/Card1";
import Heading2 from "../../components/headings/Heading2";
import Label1 from "../../components/labels/Label1";
import InputField from "../../components/inputs/InputField";
import Button1 from "../../components/buttons/Button1";
import axiosFetch from "../../axiosConfig";
import Loader from "../../components/loaders/Loader";

const UpdateProfile = () => {
  const dispatch = useDispatch();

  // contant to use useTranslation() hook
  const { t } = useTranslation();

  const [userName, setUserName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [language, setLanguage] = useState("");
  const [timezone, setTimezone] = useState("");
  const [loader, setLoader] = useState(false);
  const [updateProfileresponse, setUpdateProfileresponse] = useState();
  const user = JSON.parse(localStorage.getItem("user"));

  // to get the user information
  useEffect(() => {
    dispatch(getUserProfile());
  }, []);
  // getting the userProfileData from user state
  const {
    userProfile: { profileData },
  } = useSelector((state) => state.user);

  // to get timezones list
  useEffect(() => {
    dispatch(getTimeZones());
  }, []);
  // getting timezones list from user state
  const {
    getTimeZone: { timeZonesList },
  } = useSelector((state) => state.user);
  // console.log(timeZonesList);

  // initializing all the variables
  useEffect(() => {
    setUserName(profileData?.username ? profileData?.username : "");
    setFirstname(profileData?.first_name ? profileData?.first_name : "");
    setLastname(profileData?.last_name ? profileData?.last_name : "");
    setLanguage(profileData?.language ? profileData?.language : "");
    setTimezone(profileData?.timezone ? profileData?.timezone : "");
  }, [profileData]);

  // input validations, start

  const [inputError, setInputError] = useState({
    userName: "",
  });

  //payload to send with API
  const userUpdatedDetails = {
    username: userName,
    first_name: firstname,
    last_name: lastname,
    language: language,
    timezone: timezone,
  };
  // console.log(userUpdatedDetails);

  //username validation
  const handleUserName = (value) => {
    setUserName(value);
    let userRegex = /^[a-zA-Z0-9_]{6,30}$/;
    if (userName?.length < 6)
      setInputError({
        ...inputError,
        userName: "length should be greater than 6",
      });
    else if (!userRegex.test(userName)) setInputError({ ...inputError, userName: "Invalid Input" });
    else setInputError({ ...inputError, userName: "" });
  };

  // to disable the sign up button until all fields are filled
  const isDisabled = userName?.length < 6 || inputError?.userName ? true : false;

  // to call the Update user profile API
  const updateUser = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(UPDATE_PROFILE, userUpdatedDetails);
      setUpdateProfileresponse(resp);
      if (resp.status === 200) {
        const tempUser = user;
        tempUser.fullname = resp?.data?.first_name + " " + resp?.data?.last_name;
        tempUser.language = resp?.data?.language;
        tempUser.timezone = resp?.data?.timezone;
        tempUser.username = resp?.data?.username;
        localStorage.setItem("user", JSON.stringify(tempUser));
        setLoader(false);
      }
    } catch (error) {
      setUpdateProfileresponse(error?.response);
      setLoader(false);
    }
  };
  // console.log(updateProfileresponse);

  return (
    <div>
      <DashboardWrapper>
        <Card1>
          <div>
            <Heading2>{t("updateProfile")}</Heading2>
          </div>
          <div className="cd-input-container">
            <Label1>{t("username")}:</Label1>
            <InputField
              className="cd-input-field"
              name="cd_user_name"
              placeholder={t("placeholderUsername")}
              value={userName}
              onChange={(e) => handleUserName(e.target.value)}
            ></InputField>
          </div>
          {inputError?.userName && <div className="cd-err-msg">{t("userNameValidationMsg")}</div>}

          <div className="cd-input-container">
            <Label1>{t("firstName")}:</Label1>
            <InputField
              className="cd-input-field"
              name="cd_user_fname"
              placeholder={t("placeholderFirstName")}
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            ></InputField>
          </div>
          <div className="cd-input-container">
            <Label1>{t("lastName")}:</Label1>
            <InputField
              className="cd-input-field"
              name="cd_user_lname"
              placeholder={t("placeholderLastName")}
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            ></InputField>
          </div>
          <div className="cd-input-container">
            <Label1>{t("language")} :</Label1>
            <select
              name="language"
              id="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="">{t("selectLanguage")}</option>
              <option value="hn">{t("hindi")}</option>
              <option value="en">{t("english")}</option>
            </select>
          </div>
          <div className="cd-input-container">
            <Label1>{t("timezone")} :</Label1>
            <select
              name="timezone"
              id="timezone"
              value={timezone}
              onChange={(e) => setTimezone(e.target.value)}
            >
              <option value="">{t("selectTimezone")}</option>
              {timeZonesList?.map((data, index) => (
                <option key={index} value={data.code}>
                  {data.title}
                </option>
              ))}
            </select>
          </div>
          {/* code block to show the loader conditionally */}
          {loader && <Loader />}

          {/* code block to show the succesful response conditionally */}
          {updateProfileresponse?.status === 200 && (
            <div className="cd-success-msg">{t("profileUpdated")}</div>
          )}
          <Button1 className="cd-btn" disabled={isDisabled} onClick={updateUser}>
            {t("submit")}
          </Button1>
        </Card1>
      </DashboardWrapper>
    </div>
  );
};

export default UpdateProfile;
