import React from "react";
import "./loader2.css";

const Loader2 = () => {
  return (
    <div align="center" style={{ display: "inline", margin: "5px", position: "relative" }}>
      <span className="cd-loader-2"></span>
    </div>
  );
};

export default Loader2;
