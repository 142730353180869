import React from "react";
import { Navigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardSidebar from "../sidebars/DashboardSidebar";
import Navbar from "../commoncomps/Navbar";
import MenuItem from "../commoncomps/MenuItem";

const DashboardWrapper = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  // getting currency from localstorage
  let currency = localStorage.getItem("currencyCMS");
  // if currency not set yet then set to default INR
  if (!currency) {
    localStorage.setItem("currencyCMS", "INR");
  }

  // constant to use useTranslation() hook
  const { t } = useTranslation();

  const pathname = window.location.pathname;

  let organisationPathNames = [
    "/create-organisation",
    "/view-organisation",
    "/organisation-users",
    "/organisation-roles",
    "/organisation-create-role",
    "/view-org-user",
    "/assign-role",
    "/all-organisations",
  ];
  if (!user) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="cd_dashboard_wrapper clearfix">
      <div className="cd_dashboard_left_section">
        <DashboardSidebar>
          <MenuItem
            className={`${pathname === "/view-profile" || pathname === "/update-profile" || pathname === "/update-profile-photo" ? "cd-active" : ""}`}
          >
            <Link className="cd-common-anchor-tag" to="/view-profile">
              {t("profile")}
            </Link>
          </MenuItem>
          <MenuItem
            className={`${pathname === "/all-subscriptions" || pathname.startsWith("/subscription-details") ? "cd-active" : ""}`}
          >
            <Link className="cd-common-anchor-tag" to="/all-subscriptions">
              {t("subscription")}
            </Link>
          </MenuItem>
          <MenuItem
            className={`${organisationPathNames.some((path) => pathname.startsWith(path)) ? "cd-active" : ""}`}
          >
            <Link className="cd-common-anchor-tag" to="/all-organisations">
              {t("organization")}
            </Link>
          </MenuItem>
        </DashboardSidebar>
      </div>
      <div className="cd_dashboard_right_section">
        <Navbar />
        <div className="cd_dashboard_content">{props.children}</div>
      </div>
    </div>
  );
};

export default DashboardWrapper;
