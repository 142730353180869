import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ORGANISATION_VERIFY_USER_INVITATION } from "../../api";
import axiosFetch from "../../axiosConfig";
import Loader2 from "../../components/loaders/Loader2";

const VerifyUserInvitation = () => {
  const [response, setResponse] = useState();
  const [loader, setLoader] = useState(false);

  // getting the query parameter from URL bar
  const userInvitationToken = window.location.search;
  // console.log(userInvitationToken);
  const navigate = useNavigate();

  // verify user invitation token function on page renders
  useEffect(() => {
    const userInvitationTokenVerify = async () => {
      setLoader(true);
      try {
        const resp = await axiosFetch.get(
          ORGANISATION_VERIFY_USER_INVITATION + userInvitationToken
        );
        if (resp?.status === 202) {
          setResponse(resp);
          setLoader(false);
          // using the settimeout so that the user can read the success msg got from API
          setTimeout(() => {
            navigate("/all-organisations");
          }, 3000);
        }
      } catch (error) {
        setResponse(error.response);
        setLoader(false);
      }
    };

    //calling the function immediatly
    userInvitationTokenVerify();
  }, [userInvitationToken]);

  return (
    <div align="center">
      {loader && <Loader2 />}

      {response?.status === 202 && (
        <div className="cd-success-msg">
          Verification Success! Redirecting to organisations list...
        </div>
      )}

      {response?.status === 403 && <div className="cd-err-msg">Token Exprired</div>}
    </div>
  );
};
export default VerifyUserInvitation;
