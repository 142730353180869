import React, { useEffect, useState } from "react";
import { VERIFY_TOKEN } from "../../api";
import { useNavigate } from "react-router-dom";
import axiosFetch from "../../axiosConfig";

const VerifyToken = () => {
  const [redirect, setRedirect] = useState(false);

  // getting the query parameter from URL bar
  const url = window.location.search;
  // console.log(url);
  const navigate = useNavigate();

  // using useEffect to call the verify token function automatically when page renders
  useEffect(() => {
    const tokenVerify = async () => {
      try {
        // eslint-disable-next-line no-unused-vars
        const resp = await axiosFetch.get(VERIFY_TOKEN + url);
        setRedirect(true);
        // console.log(resp);
        // using the settimeout so that the user can read the success msg got from API
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } catch (error) {
        // setResponse(error.response);
      }
    };

    //calling the function immediatly
    tokenVerify();
  }, []);

  return (
    <div>
      {redirect ? (
        <div className="cd-success-msg">Verification Success! Redirecting to login...</div>
      ) : (
        <div className="cd-err-msg">Token Expired...</div>
      )}
      {/* {
              response?.data?.detail && (
                <div className="err_msg">
                    Token Expired...
                </div>
              )  
            } */}
    </div>
  );
};
export default VerifyToken;
