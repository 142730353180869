import axios from "axios";
import { BASE_URL } from "./api";
import { removeUserFromLocalStorage } from "./redux/handleUser";

// get the current path name
const urlArray = window.location.pathname.split("/"); // create array of url params

const axiosFetch = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// configure the request behaviour
axiosFetch.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user")); // get the user from localstorage
    // const slug = localStorage.getItem("slug"); // get the organization slug from localstorage
    const slug = urlArray[1]; // get the slug from url array
    const token = user?.token;
    if (token) {
      config.headers["Authtoken"] = `${token}`; // pass the token in headers
    }
    if (slug) {
      config.headers["slug "] = slug; // pass the slug (organisation_slug) in header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// configure the response behaviour
axiosFetch.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response.status === 401) {
      removeUserFromLocalStorage(); // remove the user from localStorage
      window.location.replace("/login"); // redirect user to login page
    } else {
      return Promise.reject(error);
    }
  }
);
export default axiosFetch;
