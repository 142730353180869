export const setUserInLocalStorage = (data) => {
  localStorage.setItem("user", data);
};

export function removeUserFromLocalStorage() {
  localStorage.removeItem("user");
}

export const scrollToTop = () => {
  const dashboardContent = document.querySelector(".cd_dashboard_content");
  dashboardContent.scrollTo({
    top: 0,
  });
};
