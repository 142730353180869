import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProjectUsers, getProjectTasks } from "../../redux/slice/taskManagementSlice";
import { TmASSIGN_TASK, TmWITHDRAW_TASK } from "../../api";
import TmAddProjectUser from "./TmAddProjectUser";
import Button1 from "../../components/buttons/Button1";
import Loader2 from "../../components/loaders/Loader2";
import Heading2 from "../../components/headings/Heading2";
import Label1 from "../../components/labels/Label1";
import axiosFetch from "../../axiosConfig";
import Modal from "../../components/modal/Modal";

const TmAssignTask = ({ task, projectId, limit, currentPage }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [assigneeUser, setAssigneeUser] = useState("");
  const [withdrawUser, setWithdrawUser] = useState("");
  const [assignTaskResponse, setAssignTaskResponse] = useState("");
  const [withdrawTaskResponse, setWithdrawTaskResponse] = useState("");
  const [showAddUser, setShowAddUser] = useState(false);
  const [userAdded, setUserAdeed] = useState(false);

  // memoize the value of passed task through props
  const passedTask = useMemo(() => {
    return task;
  }, [task]);

  // get project user from redux state
  const {
    getProjectUsers: { projectUsers },
  } = useSelector((state) => state.taskManagement);

  // dispatch the action to get all project user
  useEffect(() => {
    dispatch(
      getProjectUsers({
        projectId: projectId,
      })
    );
  }, [projectId, userAdded]);

  // constant to disable the submit button
  const isDisabled = assigneeUser?.length === 0 ? true : false;

  // function to call the API of assign task
  let assignUserPayload = {
    tuid: passedTask.tuid,
    user_id: assigneeUser,
  };
  // console.log(assignUserPayload);
  const assignTask = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(TmASSIGN_TASK, assignUserPayload);
      setAssignTaskResponse(resp.data);
      if (resp.status === 201) {
        dispatch(
          getProjectTasks({
            projectId: projectId,
            limit: limit,
            offset: limit * (currentPage - 1),
          })
        );
        setLoader(false);
      }
    } catch (error) {
      setAssignTaskResponse(error.response);
      setLoader(false);
    }
  };

  // function to call the withdraw task api
  let withdrawTaskPayload = {
    tuid: passedTask.tuid,
    user_id: withdrawUser.uuid,
  };
  const withdrawTask = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(TmWITHDRAW_TASK, withdrawTaskPayload);
      setWithdrawTaskResponse(resp.data);
      if (resp?.status === 200) {
        dispatch(
          getProjectTasks({
            projectId: projectId,
            limit: limit,
            offset: limit * (currentPage - 1),
          })
        );
        setLoader2(false);
      }
    } catch (error) {
      setWithdrawTaskResponse(error.response);
      setLoader2(false);
    }
  };
  // console.log(assignTaskResponse);

  return (
    <div>
      <Heading2 align="center">Assign task</Heading2>
      <div>Title : {passedTask?.title}</div>
      <div>Estimated Hours : {passedTask?.estimate_hours}</div>
      <div>Deadline : {passedTask?.deadline}</div>
      {passedTask.assigned_to.length !== 0 && (
        <>
          <div>
            Assigned to :
            {passedTask.assigned_to.map((assignee, index) => (
              <div key={index}>
                {index + 1} : {assignee.user.username}{" "}
                <span
                  onClick={() =>
                    setWithdrawUser({ uuid: assignee.user.uuid, username: assignee.user.username })
                  }
                  className="cd-cross-mark"
                >
                  &times;
                </span>
              </div>
            ))}
          </div>
        </>
      )}
      <br />
      {withdrawUser?.uuid && (
        <Modal
          handleModal={() => {
            setWithdrawUser("");
            setWithdrawTaskResponse("");
          }}
        >
          <h2>Withdraw task</h2>
          <div>Are you sure want to withdraw task from "{withdrawUser?.username}"</div>
          <div>
            <Button1 onClick={withdrawTask}>Yes {loader2 && <Loader2 />}</Button1>
            <Button1 onClick={() => setWithdrawUser("")}>No</Button1>
          </div>
          {withdrawTaskResponse?.tuid && (
            <div className="cd-success-msg">Task withdraw successfully</div>
          )}
          {withdrawTaskResponse?.data?.detail && (
            <div className="cd-err-msg cd-msg-container">
              {withdrawTaskResponse.data?.detail[0]?.msg}
            </div>
          )}
          {withdrawTaskResponse?.data?.detail?.detail && (
            <div className="cd-err-msg cd-msg-container">
              {withdrawTaskResponse.data?.detail?.detail[0]?.msg}
            </div>
          )}
        </Modal>
      )}
      <div className="">
        <Label1>Select Assignee:</Label1>
        <select
          name="assignee"
          id="assignee"
          className="cd-input-field"
          onChange={(e) => setAssigneeUser(e.target.value)}
        >
          <option value="">Select</option>
          {projectUsers?.project_users?.map((user, index) => {
            if (user?.is_active)
              return (
                <option key={index} value={user.user.uuid}>
                  {user.user.username}
                </option>
              );
          })}
        </select>
      </div>
      {assignTaskResponse?.tuid && <div className="cd-success-msg">Task assigned successfully</div>}
      {assignTaskResponse?.data?.detail && (
        <div className="cd-err-msg cd-msg-container">{assignTaskResponse.data?.detail[0]?.msg}</div>
      )}
      {assignTaskResponse?.data?.detail?.detail && (
        <div className="cd-err-msg cd-msg-container">
          {assignTaskResponse.data?.detail?.detail[0]?.msg}
        </div>
      )}
      <div>
        <Button1 disabled={isDisabled} onClick={assignTask}>
          Assign
          {loader && <Loader2 />}
        </Button1>
      </div>
      <br />
      <div className="cd-no-project-user-msg">
        {projectUsers?.total === 0 && (
          <div className="cd-err-msg">No user found, please click on below button to add one</div>
        )}
        <div>
          <Button1 onClick={() => setShowAddUser(!showAddUser)}>Add Project User</Button1>
        </div>
        {showAddUser && (
          <Modal handleModal={() => setShowAddUser(!showAddUser)}>
            <TmAddProjectUser projectId={projectId} userAdded={() => setUserAdeed(!userAdded)} />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default TmAssignTask;
