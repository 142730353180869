import React from "react";
import Heading2 from "../components/headings/Heading2";
import { Navigate } from "react-router-dom";

const Home = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const slug = localStorage.getItem("slug");
  if (user) {
    if (slug) {
      return <Navigate to={`/${slug}/view-organisation`} />;
    } else {
      return <Navigate to="/all-organisations" />;
    }
  }
  return (
    <div align="center">
      <div>
        <img src="/company-logo.jpg" alt="" />
      </div>
      <div>
        <Heading2>Welcome to Code CMS</Heading2>
        <div>
          Please{" "}
          <a className="cd-common-anchor-tag" href="/login">
            login
          </a>{" "}
          to continue
        </div>
      </div>
    </div>
  );
};

export default Home;
