import { React, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { ORGANISATION_UPDATE } from "../../api";
import { getAllOrganisations } from "../../redux/slice/organisationsSlice";
import InputField from "../../components/inputs/InputField";
import Heading2 from "../../components/headings/Heading2";
import Label1 from "../../components/labels/Label1";
import Button1 from "../../components/buttons/Button1";
import axiosFetch from "../../axiosConfig";
import Modal from "../../components/modal/Modal";
import Loader2 from "../../components/loaders/Loader2";
import OrganisationUpdateIcon from "./OrganisationUpdateIcon";

const OrganisationUpdate = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(); // constant to use this hook

  const [organisationTitle, setOrganisationTitle] = useState(props.organisationDetails.title);
  const [organisationActivityStatus, setOrganisationActivityStatus] = useState(
    props.organisationDetails.is_active
  );
  const [showUpdateLogoModal, setShowUpdateLogoModal] = useState(false);
  const [showActivityStatus, setShowActivityStatus] = useState(false);
  const [updateOrgResponse, setUpdateOrgResponse] = useState("");
  const [loader, setLoader] = useState(false);

  // to call the API of update organisation
  let updateOrganisationPayload = {
    title: organisationTitle,
    is_active: organisationActivityStatus,
  };
  const updateOrganisation = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(ORGANISATION_UPDATE, updateOrganisationPayload);
      setUpdateOrgResponse(resp.data);
      // call the API the organisation list & the organisation detail
      if (resp.status === 200) {
        props.organisationUpdate();
        dispatch(getAllOrganisations());
        setLoader(false);
      }
    } catch (error) {
      setUpdateOrgResponse(error.response);
      setLoader(false);
    }
  };

  const callUpdateOrgansation = () => {
    props.organisationUpdate();
  };
  return (
    <>
      <Heading2 align="center">{t("updateOrg")} </Heading2>
      <div className="cd-input-container">
        <Label1>{t("name")} :</Label1>
        <InputField
          placeholder={t("placeholderOrgTitle")}
          value={organisationTitle}
          name="cd_org_update_title"
          onChange={(e) => setOrganisationTitle(e.target.value)}
        ></InputField>
      </div>
      <div className="cd-input-container">
        <Label1>{t("activeStatus")} :</Label1>
        <span className={`${organisationActivityStatus ? "cd-success-msg" : "cd-err-msg"}`}>
          {" "}
          {organisationActivityStatus ? t("active") : t("deactivate")}{" "}
        </span>
        <Button1 onClick={() => setShowActivityStatus(!showActivityStatus)}>{t("change")} </Button1>
        {showActivityStatus && (
          <select
            name="activitySelect"
            id="activitySelect"
            className="cd-input-field"
            value={organisationActivityStatus}
            onChange={(e) => setOrganisationActivityStatus(e.target.value)}
          >
            <option value="true">{t("active")} </option>
            <option value="false">{t("deactivated")} </option>
          </select>
        )}
      </div>
      {updateOrgResponse?.title && <div className="cd-success-msg">{t("orgUpdateMsg")} !</div>}
      {updateOrgResponse?.data?.detail && (
        <div className="cd-err-msg">{updateOrgResponse?.data?.detail[0]?.msg}</div>
      )}
      {updateOrgResponse?.data?.detail?.detail && (
        <div className="cd-err-msg">{updateOrgResponse?.data?.detail?.detail[0]?.msg}</div>
      )}
      <Button1 onClick={updateOrganisation}>
        {t("update")} {loader && <Loader2 />}
      </Button1>
      <Button1 onClick={() => setShowUpdateLogoModal(!showUpdateLogoModal)}>
        {t("updateOrgIcon")}
      </Button1>
      {showUpdateLogoModal && (
        <Modal handleModal={() => setShowUpdateLogoModal(!showUpdateLogoModal)}>
          <OrganisationUpdateIcon
            organisationDetails={props.organisationDetails}
            updateOrganisation={callUpdateOrgansation}
          />
        </Modal>
      )}
    </>
  );
};

export default OrganisationUpdate;
