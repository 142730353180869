import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IMAGE_PATH_PREFIX } from "../../api";
import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../redux/slice/userSlice";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Heading2 from "../../components/headings/Heading2";

const ViewProfile = ({ setProgress }) => {
  // constant to use useTranslation() hook
  const { t } = useTranslation();
  // use the useDispatch hook
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(true);

  // to get the user information from user state
  const {
    userProfile: { profileData },
  } = useSelector((state) => state.user);
  // console.log(profileData);

  const user = JSON.parse(localStorage.getItem("user"));
  // console.log(userData);

  //to set the user information in userProfileDetails variable
  useEffect(() => {
    // if redux's user state has reset than fetch the data from getUserProfile API
    if (!profileData?.email) {
      setLoader(true);
      setProgress(40);
      dispatch(getUserProfile()).then(() => {
        setProgress(100);
      });
    }
    setLoader(false);
  }, [dispatch]);
  // console.log(userProfileDetails);
  return (
    <>
      <DashboardWrapper>
        <Heading2>{t("yourProfile")}</Heading2>
        <div className={loader ? "not-appeared" : "appeared"}>
          <div className="cd-user-profile-photo-container">
            <Link to="/update-profile-photo">
              {user?.profile_photo ? (
                <img
                  className="cd-user-profile-photo"
                  src={IMAGE_PATH_PREFIX + user?.profile_photo}
                  alt="profile-photo1"
                />
              ) : (
                <img className="cd-user-profile-photo" src="/user-128.png" alt="profile-photo2" />
              )}
            </Link>
          </div>
          <div>
            {t("username")} : {profileData?.username}
          </div>
          <div>
            {t("name")} : {profileData?.first_name + " " + profileData?.last_name}
          </div>
          <div>
            {t("email")} : {profileData?.email}
          </div>
          <div>
            {t("language")} : {profileData?.language}
          </div>
          <div>
            {t("timezone")} : {profileData?.timezone}
          </div>
          <div>
            {t("uniqueID")} : {profileData?.uuid}
          </div>
          <br />
          <div>
            <Link to="/update-profile" className="cd-common-anchor-tag">
              {t("updateProfile")}
            </Link>
          </div>
          <div>
            <Link to="/update-profile-photo" className="cd-common-anchor-tag">
              {t("updateProfilePhoto")}
            </Link>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default ViewProfile;
