import React from "react";

const Card1 = (props) => {
  return (
    <div className="cd_card_container">
      <div className="cd_card">{props.children}</div>
    </div>
  );
};

export default Card1;
