import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getOrgUserDetails, getAllPermissions } from "../../redux/slice/organisationsSlice";
import axiosFetch from "../../axiosConfig";
import {
  ORGANISATION_ASSIGN_USER_PERMISSION,
  ORGANISATION_UPDATE_USER_STATUS,
  ORGANISATION_DELETE_USER,
} from "../../api";
import OrganisationWrapper from "../../components/wrappers/OrganisationWrapper";
import OrganisationAssignRole from "../organisation/OrganisationAssignRole";
import Loader2 from "../../components/loaders/Loader2";
import Label1 from "../../components/labels/Label1";
import InputCheckbox from "../../components/inputs/InputCheckbox";
import Button1 from "../../components/buttons/Button1";
import Modal from "../../components/modal/Modal";

const OrganisationUserDetails = ({ setProgress }) => {
  const { uuid } = useParams();
  // console.log(uuid);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalRoleChange, setOpenModalRoleChange] = useState(false);
  const [userPermission, setUserPermission] = useState([]);
  const [updatePermissionResponse, setUpdatePermissionResponse] = useState();
  const [deativateUserWarning, setDeativateUserWarning] = useState(false);
  const [deativateUserResponse, setDeativateUserResponse] = useState({});
  const [deleteUserWarning, setDeleteUserWarning] = useState(false);
  const [deleteUserResponse, setDeleteUserResponse] = useState({});
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));

  // getting the user information on page render
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    dispatch(getOrgUserDetails(uuid)).then(() => {
      setProgress(100);
      setLoader(false);
    });

    if (allPermissions.length === 0) {
      dispatch(getAllPermissions()).then(() => {});
    }
  }, [uuid, updatePermissionResponse]);

  const {
    getOrgUserDetails: { userDetails },
  } = useSelector((state) => state.organisation);
  // console.log(userDetails);

  const {
    getAllPermissions: { allPermissions },
  } = useSelector((state) => state.organisation);
  // console.log(allPermissions);

  const handleModal = () => {
    setOpenModal(!openModal);
    // set existing permissions in userPermission array
    let tempPermissions = [];
    userDetails?.permissions?.map((permission) => {
      // console.log(permission.permission);
      tempPermissions.push(permission.codename);
      setUserPermission(tempPermissions);
    });
  };

  // function to handle the permission codenames in an array
  const handlePermission = (value) => {
    let tempIndex = userPermission.indexOf(value);
    if (tempIndex === -1) {
      let tempPermission = [...userPermission];
      tempPermission.push(value);
      setUserPermission(tempPermission);
    } else {
      let tempPermission = [...userPermission];
      tempPermission.splice(tempIndex, 1);
      setUserPermission(tempPermission);
    }
  };
  // console.log(userPermission);

  let userPermissionPayload = {
    uuid: uuid,
    permissions: userPermission,
  };
  const updateUserPermission = async () => {
    // do nothing if user trying to update self permission
    if (user?.username === userDetails?.user?.username) return;
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(
        ORGANISATION_ASSIGN_USER_PERMISSION,
        userPermissionPayload
      );
      setLoader2(false);
      setUpdatePermissionResponse(resp.data);
      // console.log(resp);
    } catch (error) {
      setLoader2(false);
      setUpdatePermissionResponse(error.response);
      // console.log(error.response);
    }
  };

  // function to call the API of deactivate user
  const deativateUser = async (statusValue) => {
    try {
      const resp = await axiosFetch.post(ORGANISATION_UPDATE_USER_STATUS, {
        user_uid: uuid,
        is_active: statusValue,
      });
      if (resp.status == 200) {
        setDeativateUserResponse(resp.data);
        dispatch(getOrgUserDetails(uuid));
      }
    } catch (error) {
      setDeativateUserResponse(error.response);
    }
  };

  // function to call the API of delete user
  const deleteUser = async () => {
    try {
      const resp = await axiosFetch.delete(ORGANISATION_DELETE_USER + uuid);
      setDeleteUserResponse(resp.data);
    } catch (error) {
      setDeleteUserResponse(error.response);
    }
  };

  const closeModal = () => {
    setDeativateUserWarning(false);
    setDeleteUserWarning(false);
    setDeleteUserResponse({});
  };

  //to disable the update button
  const isDisable = user?.username === userDetails?.user?.username ? true : false;
  return (
    <OrganisationWrapper>
      <div className={`cd-org-user-info-container ${loader ? "not-appeared" : "appeared"}`}>
        <div>
          <div className="cd-user-profile-photo-container">
            {userDetails?.user?.profile_photo ? (
              <img
                className="cd-user-profile-photo"
                src={`https://codecms.codeholic.in/static/uploads/${userDetails?.user?.profile_photo}`}
                alt="profile-photo1"
              />
            ) : (
              <img className="cd-user-profile-photo" src="/user-128.png" alt="profile-photo2" />
            )}
          </div>
          <div>
            {t("username")} : {userDetails?.user?.username}
          </div>
          <div>
            {t("email")} : {userDetails?.user?.email}
          </div>
          <div>
            {t("role")} : {userDetails?.role?.title}
          </div>
        </div>
        <div>
          <Button1
            onClick={(e) => setOpenModalRoleChange(!openModalRoleChange)}
            className="cd-common-anchor-tag"
          >
            {t("changeRole")}
          </Button1>
        </div>
        <div>
          <Button1 onClick={handleModal}>{t("assignUserPermission")} </Button1>
        </div>
        {userDetails?.is_active && (
          <div
            className="cd-err-msg"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDeativateUserWarning(!deativateUserWarning);
              setDeativateUserResponse({});
            }}
          >
            {t("deactivateUser")}
          </div>
        )}
        {deativateUserWarning && (
          <Modal
            handleModal={() => {
              setDeativateUserWarning(!deativateUserWarning);
              setDeativateUserResponse({});
            }}
          >
            <div className="cd-warning=msg">
              <div>{t("areYouSureDeactivateUser")} </div>
              <Button1 style={{ cursor: "pointer" }} onClick={() => deativateUser(false)}>
                {t("yes")}
              </Button1>
              <Button1 style={{ cursor: "pointer" }} onClick={closeModal}>
                {t("no")}
              </Button1>
            </div>
            {/* show the API response */}
            {deativateUserResponse?.user && (
              <div className="cd-success-msg">{t("userUpdated")}! </div>
            )}
            {deativateUserResponse?.data?.detail && (
              <div className="cd-err-msg">{deativateUserResponse?.data?.detail[0]?.msg}</div>
            )}
            {deativateUserResponse?.data?.detail?.detail && (
              <div className="cd-err-msg">{deativateUserResponse?.data?.detail?.detail[0].msg}</div>
            )}
          </Modal>
        )}
        {!userDetails?.is_active && (
          <>
            <div
              className="cd-success-msg"
              style={{ cursor: "pointer" }}
              onClick={() => deativateUser(true)}
            >
              {t("activateUser")}
            </div>
          </>
        )}

        <div
          className="cd-err-msg"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setDeleteUserWarning(!deleteUserWarning);
            setDeleteUserResponse({});
          }}
        >
          {t("deleteUser")}
        </div>
        {deleteUserWarning && (
          <Modal handleModal={closeModal}>
            <div className="cd-warning-msg">
              <div>{t("areYouSureDeleteUser")} </div>
              <Button1 style={{ cursor: "pointer" }} onClick={() => deleteUser()}>
                {t("yes")}
              </Button1>
              <Button1 style={{ cursor: "pointer" }} onClick={closeModal}>
                {t("no")}
              </Button1>
            </div>
            {deleteUserResponse?.user && <div className="cd-err-msg">{t("userUpdated")}!</div>}
            {deleteUserResponse?.data?.detail && (
              <div className="cd-err-msg">{deleteUserResponse?.data?.detail[0]?.msg}</div>
            )}
            {deleteUserResponse?.data?.detail?.detail && (
              <div className="cd-err-msg">{deleteUserResponse?.data?.detail?.detail[0]?.msg}</div>
            )}
          </Modal>
        )}
      </div>

      {/* open modal to assign permission page */}
      {openModalRoleChange && (
        <Modal handleModal={(e) => setOpenModalRoleChange(!openModalRoleChange)}>
          <OrganisationAssignRole />
        </Modal>
      )}
      {openModal && (
        <Modal handleModal={handleModal}>
          <div align="center">
            <img
              className="cd-user-profile-thumbnail"
              src={`https://codecms.codeholic.in/static/uploads/${userDetails?.user?.profile_photo}`}
              alt=""
            />
          </div>
          <div>
            <Label1>{t("permissions")} : </Label1>
            {allPermissions.map((permission, index) => (
              <div key={index}>
                <InputCheckbox
                  value={permission.codename}
                  id={permission.codename}
                  name={permission.codename}
                  onChange={(e) => handlePermission(e.target.value)}
                  checked={userPermission.includes(permission.codename)}
                ></InputCheckbox>
                <label htmlFor={permission.codename}> {permission.permission}</label>
              </div>
            ))}
          </div>
          {updatePermissionResponse?.user && (
            <div className="cd-success-msg">{t("permissionUpdateMsg")} </div>
          )}
          {updatePermissionResponse?.status !== 200 && (
            <div className="cd-err-msg">
              {updatePermissionResponse?.data?.detail?.detail[0]?.msg}
            </div>
          )}
          <Button1 onClick={updateUserPermission} disabled={isDisable}>
            {t("update")} {loader2 && <Loader2 />}
          </Button1>
        </Modal>
      )}
    </OrganisationWrapper>
  );
};

export default OrganisationUserDetails;
