import React from "react";
import "../../style/modal.css";

/**
 * A JSX component to add a modal
 * @param {function} handleModal - function to handle/close the modal
 * @returns {JSX.Element} - A UI of modal
 */

const Modal = (props) => {
  const closeModal = () => {
    props.handleModal();
  };
  return (
    <div className="cd_modal">
      <div className="cd_modal-content clearfix">
        <span onClick={closeModal} className="cd_modal_close">
          &times;
        </span>
        <div>{props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
