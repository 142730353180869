import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOGIN_ENDPOINT, GET_PROFILE_DETAIL, GET_TIMEZONES, LOGOUT_ENDPOINT } from "../../api";
import axiosFetch from "../../axiosConfig";
import { removeUserFromLocalStorage, setUserInLocalStorage } from "../handleUser";

export const userLogin = createAsyncThunk("userLogin", async (data) => {
  try {
    const resp = await axiosFetch.post(LOGIN_ENDPOINT, data);
    const user = {
      token: resp?.data?.token,
      fullname: resp?.data?.user?.first_name + " " + resp?.data?.user?.last_name,
      email: resp?.data?.user?.email,
      language: resp?.data?.user?.language,
      username: resp?.data?.user?.username,
      profile_photo: resp?.data?.user?.profile_photo,
    };
    if (resp.status === 200) setUserInLocalStorage(JSON.stringify(user));
    return resp;
  } catch (error) {
    return error?.response;
  }
});

export const getUserProfile = createAsyncThunk("getUserProfile", async () => {
  try {
    const resp = await axiosFetch.get(GET_PROFILE_DETAIL);
    return resp?.data;
  } catch (error) {
    return error?.response;
  }
});

export const getTimeZones = createAsyncThunk("getTimeZones", async () => {
  try {
    const resp = await axiosFetch.get(GET_TIMEZONES);
    return resp?.data;
  } catch (error) {
    return error?.response;
  }
});

export const userLogout = createAsyncThunk("userLogout", async () => {
  try {
    const resp = await axiosFetch.delete(LOGOUT_ENDPOINT);
    removeUserFromLocalStorage();
    return resp;
  } catch (error) {
    return error;
  }
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    userLogin: {
      isLoading: false,
      userData: [],
    },
    userProfile: {
      isLoading: false,
      profileData: [],
    },
    getTimeZone: {
      timeZonesList: [],
    },
  },
  extraReducers: (builder) => {
    // to login the user
    builder.addCase(userLogin.pending, () => {});
    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
      state.userLogin.userData = payload?.data;
    });
    builder.addCase(userLogin.rejected, (state, { payload }) => {
      state.userLogin.userData = payload;
    });

    // to logout the user
    builder.addCase(userLogout.pending, (state) => {});
    builder.addCase(userLogout.fulfilled, (state, { payload }) => {
      state.userLogin.userData = "";
    });

    //to get user details
    builder.addCase(getUserProfile.pending, (state) => {});
    builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
      state.userProfile.profileData = payload;
    });
    builder.addCase(getUserProfile.rejected, (state, { payload }) => {
      state.userProfile.profileData = payload;
    });

    // to get all timezones
    builder.addCase(getTimeZones.pending, (state) => {});
    builder.addCase(getTimeZones.fulfilled, (state, { payload }) => {
      state.getTimeZone.timeZonesList = payload;
    });
    builder.addCase(getTimeZones.rejected, (state, { payload }) => {
      state.getTimeZone.timeZonesList = payload?.data;
    });
  },
});
export default userSlice.reducer;
