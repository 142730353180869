import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Heading2 from "../../components/headings/Heading2";
import InputFile from "../../components/inputs/InputFile";
import Button1 from "../../components/buttons/Button1";
import Loader2 from "../../components/loaders/Loader2";
import axiosFetch from "../../axiosConfig";
import { getAllOrganisations } from "../../redux/slice/organisationsSlice";
import { ORGANISATION_UPDATE_ICON } from "../../api";

const OrganisationUpdateIcon = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState("");
  const [organisationIconUpdateResponse, setOrganisationIconUpdateResponse] = useState("");
  const [loader2, setLoader2] = useState(false);
  const [organisationIcon, setOrganisationIcon] = useState(false);
  const [disableUpdateBtn, setDisableUpdateBtn] = useState(false);
  const [validationError, setValidationError] = useState({
    size_error: "",
    extension_error: "",
  });

  // function to handle the uploaded image and to add validation in image input
  const handleUploadedImage = async (file) => {
    if (file) {
      //regular expression to check image extension
      const allowedExtensions = /\.(png|jpg|gif|jpeg)$/i;
      const uploadError = {
        size_error: "",
        extension_error: "",
      };

      //if file size is greater than 5MB than update the uploadError.extension_error
      if (file.size > 5 * 1024 * 1024) {
        uploadError.size_error = `${t("profilePhotoSizeError")}`;
      }

      //if file size is greater than 5MB than update the uploadError.size_error
      if (!allowedExtensions.exec(file.name)) {
        uploadError.extension_error = `${t("profilePhotoExtensionError")}`;
      }
      setValidationError(uploadError);

      //disable the update button if uploadError object has any value and return
      if (uploadError.extension_error || uploadError.size_error) {
        setDisableUpdateBtn(true);
        return;
      }

      setDisableUpdateBtn(false);
      setOrganisationIcon(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // function to call the API of update organisation icon
  const updateOrgIcon = async () => {
    setLoader2(true);
    try {
      const formData = new FormData();
      formData.append("image", organisationIcon);

      const resp = await axiosFetch.post(ORGANISATION_UPDATE_ICON, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (resp.status === 200) {
        dispatch(getAllOrganisations());
        props.updateOrganisation();
      }
      setOrganisationIconUpdateResponse(resp.data);
      setLoader2(false);
    } catch (error) {
      // console.log(error.response);
      setOrganisationIconUpdateResponse(error.response);
      setLoader2(false);
    }
  };

  // constant to disable the update btn
  const isDisabled = !organisationIcon || disableUpdateBtn;
  return (
    <>
      <Heading2 align="center">{t("updateIcon")}</Heading2>
      <div align="center" className="cd-user-profile-photo-container">
        {imagePreview ? (
          <img className="cd-user-profile-photo" src={imagePreview} alt="organisation icon" />
        ) : props.organisationDetails.organization_photo ? (
          <img
            className="cd-user-profile-photo"
            src={`https://codecms.codeholic.in/static/uploads/${props.organisationDetails.organization_photo}?${Math.random(1, 10)}`}
            alt="organisation icon"
          />
        ) : (
          <img
            className="cd-user-profile-photo"
            src="/organisation-logo.jpg"
            alt="organisation icon"
          />
        )}
      </div>
      <div>
        <InputFile
          className=""
          name="cd_org_icon"
          accept="image"
          onChange={(e) => handleUploadedImage(e.target.files[0])}
        ></InputFile>
      </div>
      {/* code block to show the validation error conditonally */}
      {validationError?.size_error && (
        <div className="cd-err-msg">{validationError.size_error}</div>
      )}
      {validationError?.extension_error && (
        <div className="cd-err-msg">{validationError.extension_error}</div>
      )}

      {/* show the success or error msg from API response */}
      {organisationIconUpdateResponse?.orguid && (
        <div className="cd-success-msg">{t("orgUpdateMsg")} </div>
      )}
      {organisationIconUpdateResponse?.data?.detail && (
        <div className="cd-err-msg">{organisationIconUpdateResponse?.data?.detail[0]?.msg}</div>
      )}
      {organisationIconUpdateResponse?.data?.detail?.detail && (
        <div className="cd-err-msg">
          {organisationIconUpdateResponse?.data?.detail?.detail[0]?.msg}
        </div>
      )}
      <Button1 onClick={updateOrgIcon} disabled={isDisabled}>
        {t("update")} {loader2 && <Loader2 />}
      </Button1>
    </>
  );
};

export default OrganisationUpdateIcon;
