import { React, useState } from "react";
import { useTranslation } from "react-i18next";

import { TmCREATE_TASK_GROUP } from "../../api";
import Heading2 from "../../components/headings/Heading2";
import Label1 from "../../components/labels/Label1";
import InputField from "../../components/inputs/InputField";
import Button1 from "../../components/buttons/Button1";
import Loader2 from "../../components/loaders/Loader2";
import axiosFetch from "../../axiosConfig";

const TmCreateTaskGroup = (props) => {
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation(); // const to use this hook
  const [taskGroupTitle, setTaskGroupTitle] = useState("");
  const [createTaskGroupResponse, setCreateTaskGroupResponse] = useState("");

  // getting project id from props
  const projectId = props.projectId;
  // console.log(projectId);

  // function to call the API of createTaskGroup
  let createTaskGroupPayload = {
    title: taskGroupTitle,
    project_id: projectId,
  };
  // console.log(createTaskGroupPayload);
  const createTaskGroup = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(TmCREATE_TASK_GROUP, createTaskGroupPayload);
      setCreateTaskGroupResponse(resp.data);
      // console.log(resp);
      setLoader(false);
      props.taskGroupCreate();
    } catch (error) {
      // console.log(error.response);
      setCreateTaskGroupResponse(error.response);
      setLoader(false);
    }
  };

  return (
    <>
      <div>
        <Heading2 align="center">{t("createTaskGroup")} </Heading2>
      </div>
      <div>
        <div className="cd-input-container">
          <Label1>{t("name")} :</Label1>
          <InputField
            placeholder={t("placeholderTaskGroupTitle")}
            value={taskGroupTitle}
            name="cd_task_group_title"
            onChange={(e) => setTaskGroupTitle(e.target.value)}
          ></InputField>
        </div>
        {createTaskGroupResponse?.guid && (
          <div className="cd-msg-container cd-success-msg">{t("taskGroupCreatedMsg")} !</div>
        )}
        {createTaskGroupResponse?.data?.detail && (
          <div className="cd-msg-container cd-err-msg">
            {createTaskGroupResponse?.data?.detail[0]?.msg}
          </div>
        )}
        {createTaskGroupResponse?.data?.detail?.detail && (
          <div className="cd-msg-container cd-err-msg">
            {createTaskGroupResponse?.data?.detail?.detail[0]?.msg}
          </div>
        )}
        <div>
          <Button1 onClick={createTaskGroup}>
            {t("create")} {loader && <Loader2 />}
          </Button1>
        </div>
      </div>
    </>
  );
};

export default TmCreateTaskGroup;
