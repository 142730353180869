import React from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardWrapper from "../components/wrappers/DashboardWrapper";
import Heading2 from "../components/headings/Heading2";

const Dashboard = () => {
  //redirect user to dashboard if user is already login
  const user = JSON.parse(localStorage.getItem("user"));

  // constant to use translation hook
  const { t } = useTranslation();

  // getting currency from localstorage
  let currency = localStorage.getItem("currencyCMS");
  // if currency not set yet then set to default INR
  if (!currency) {
    localStorage.setItem("currencyCMS", "INR");
  }

  if (!user) {
    return <Navigate to="/login" />;
  }
  return (
    <DashboardWrapper>
      <Heading2>
        {t("welcome")} ! {user?.fullname}
      </Heading2>
    </DashboardWrapper>
  );
};

export default Dashboard;
