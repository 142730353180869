import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
import { useTranslation } from "react-i18next";

import { CREATE_ORDER, CREATE_TRANSACTIONS, CALCULATE_AMOUNT } from "../../api";
import axiosFetch from "../../axiosConfig";
import Button1 from "../../components/buttons/Button1";
import ApplyCoupon from "../../components/commoncomps/ApplyCoupon";

const RazorpayCreateOrder = (props) => {
  // contant to use useTranslation() hook
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  // constant to use useNavigate() hook
  const navigate = useNavigate();

  // getting currency from localstorage
  let currency = localStorage.getItem("currencyCMS");

  const [razorPay, setRazorpay] = useState(null);
  const [createOrderResponse, setCreateOrderResponse] = useState(null);
  const [loader2, setLoader2] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showApplyCoupon, setShowApplyCoupon] = useState("");
  const [coupon, setCoupon] = useState("");
  const [applyCouponResponse, setApplyCouponResponse] = useState("");
  const [Razorpay] = useRazorpay();

  const calculateAmount = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(CALCULATE_AMOUNT, {
        product_id: props.suid,
        coupon_code: coupon,
        state: props.state,
        country: props.country,
        currency: currency,
      });
      setLoader2(false);
      setShowApplyCoupon(false);
      setApplyCouponResponse(resp);
    } catch (error) {
      // console.log(error.response);
      setLoader2(false);
    }
  };

  const initialize = async () => {
    try {
      const resp = await axiosFetch.post(CREATE_ORDER, {
        suid: props.suid,
        currency: currency,
        state: props.state,
        country: props.country,
        gateway: "razorpay",
        coupon_code: coupon,
      });
      // checking if the response got from API
      if (resp) {
        setCreateOrderResponse(resp.data);
        const orderDetails = resp?.data;
        // console.log(orderDetails);
        const rzpApiKey = process.env.REACT_APP_RAZORPAY_API_KEY;
        let options = {
          key: rzpApiKey,
          amount: orderDetails?.final_amount,
          currency: orderDetails?.currency,
          name: "Code CMS",
          description: "Addiction to Code",
          image:
            "https://codeholic.in/wp-content/uploads/2022/04/cropped-face_logo_512x512-32x32.jpg",
          order_id: orderDetails?.client_secret,
          handler: async function (gatewayResponse) {
            // console.log(gatewayResponse);
            const createTransactionResponse = await axiosFetch.post(CREATE_TRANSACTIONS, {
              payment_id: gatewayResponse.razorpay_payment_id,
              payment_gateway: "razorpay",
              order_id: orderDetails?.ouid,
              status: "success",
              payment_json: gatewayResponse,
            });

            if (createTransactionResponse?.status === 201) {
              navigate(`/payment-success/${orderDetails.ouid}/success`);
            }
          },
          prefill: {
            name: props.name,
            email: props.email,
            contact: props.contact,
          },
          notes: {
            address: props.address,
          },
          theme: {
            color: "#3399cc",
          },
        };
        var rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
        });
        setRazorpay(rzp1);
      }
    } catch (error) {
      setCreateOrderResponse(error.response.data);
    }
  };

  useEffect(() => {
    initialize();
  }, [applyCouponResponse]);

  // console.log(createOrderResponse);

  const handleRazorpay = () => {
    if (razorPay) {
      razorPay.open();
    }
  };
  return (
    <>
      {createOrderResponse?.detail ? (
        <div className="cd-err-msg">{createOrderResponse?.detail?.detail[0]?.msg}</div>
      ) : (
        <div>
          <div>
            Price :{createOrderResponse?.currency === "INR" ? "₹" : ""}{" "}
            {createOrderResponse?.total_amount}
          </div>
          <div>Coupon Code : {createOrderResponse?.coupon_code}</div>
          <div>
            Coupon Amount :{createOrderResponse?.currency === "INR" ? "₹" : ""}{" "}
            {createOrderResponse?.coupon_amount?.toFixed(2)}
          </div>
          <div>
            Final Price :{createOrderResponse?.currency === "INR" ? "₹" : ""}
            {createOrderResponse?.final_amount}
          </div>

          <ApplyCoupon
            couponCode={coupon}
            onChange={(e) => setCoupon(e.target.value)}
            applyCoupon={calculateAmount}
            loader={loader2}
          ></ApplyCoupon>

          <Button1 className="cd-btn" onClick={handleRazorpay}>
            Pay with Razorpay
          </Button1>
        </div>
      )}
    </>
  );
};

export default RazorpayCreateOrder;
