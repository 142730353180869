import React, { useEffect } from "react";
import { CREATE_TRANSACTIONS } from "../../api";
import axiosFetch from "../../axiosConfig";
import { useParams } from "react-router-dom";

const PaymentSuccess = () => {
  const { ouid, status } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const paymentIntent = urlParams.get("payment_intent");
  const clientSecret = urlParams.get("payment_intent_client_secret");
  const redirectStatus = urlParams.get("redirect_status");
  useEffect(() => {
    if (redirectStatus === "succeeded" && clientSecret) {
      async function callCreateTransactionForStripe() {
        try {
          // eslint-disable-next-line no-unused-vars
          const resp = await axiosFetch.post(CREATE_TRANSACTIONS, {
            payment_id: paymentIntent,
            payment_gateway: "stripe",
            order_id: ouid,
            status: status,
            payment_json: {
              payment_intent: paymentIntent,
              payment_intent_client_secret: clientSecret,
              redirect_status: redirectStatus,
            },
          });
        } catch (error) {
          // console.log(error.response);
        }
      }
      callCreateTransactionForStripe();
    }
  }, [redirectStatus]);
  return (
    <div align="center">
      <div>
        <img src="/payment-success.png" alt="" />
      </div>
      <div className="cd-success-msg">Payment Success!</div>
    </div>
  );
};

export default PaymentSuccess;
