import { React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ORGANISATION_GET_ROLES, ORGANISATION_ASSIGN_ROLE } from "../../api";
import { getOrgUserDetails } from "../../redux/slice/organisationsSlice";
import axiosFetch from "../../axiosConfig";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import Loader2 from "../../components/loaders/Loader2";

const OrganisationAssignRole = () => {
  // getting uuid from url parameters
  const { uuid } = useParams();
  const { t } = useTranslation(); // const to use this hook
  // console.log(uuid);
  const dispatch = useDispatch();
  const [roles, setRoles] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [assignRoleResponse, setAssignRoleResponse] = useState({});
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);

  const {
    getOrgUserDetails: { userDetails },
  } = useSelector((state) => state.organisation);
  // console.log(userDetails);

  useEffect(() => {
    // getting all the roles in an organisation
    const getOrgRoles = async () => {
      setLoader(true);
      try {
        const resp = await axiosFetch.get(ORGANISATION_GET_ROLES);
        setRoles(resp.data);
        setLoader(false);
      } catch (error) {
        setRoles(error.response);
        setLoader(false);
      }
    };
    getOrgRoles();
  }, []);

  useEffect(() => {
    // if userDetails are available then  else dispatch the action to get user details
    if (userDetails?.user && selectedRole === undefined) {
      setSelectedRole(userDetails.role.ruid); // set selected role of user
    } else {
      dispatch(getOrgUserDetails(uuid)).then(() => {
        setSelectedRole(userDetails?.role?.ruid);
      });
    }
  }, []);

  // call the api of assign roles
  const assignRole = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(ORGANISATION_ASSIGN_ROLE, {
        user_id: uuid,
        ruid: selectedRole,
      });
      // console.log(resp);
      setAssignRoleResponse(resp.data);
      setLoader2(false);
      if (resp.status === 200) {
        // on role assign then dispatch the action "getOrgUserDetails" to get latest user info
        dispatch(getOrgUserDetails(uuid));
      }
    } catch (error) {
      setLoader2(false);
      setAssignRoleResponse(error.response);
      // console.log(error.response);
    }
  };

  // disable the submit button on user does'nt changed the role or selecting the same role
  const isDisabled = selectedRole === userDetails.role.ruid ? true : false;

  return (
    <div>
      <Heading2 align="center">{t("assignRole")} </Heading2>
      <br />
      {loader ? (
        <div align="center">
          <Loader2 />
        </div>
      ) : (
        <>
          <div className="cd-input-field-container">
            <select
              name="orgRoles"
              id="orgRoles"
              className="cd-input-field"
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              {roles?.roles.map((role, index) => (
                <option key={index} value={role.ruid}>
                  {role.title}
                </option>
              ))}
            </select>
          </div>
          <Button1 onClick={assignRole} disabled={isDisabled}>
            {t("select")} {loader2 && <Loader2 />}
          </Button1>
        </>
      )}
      {assignRoleResponse?.user && <div className="cd-success-msg">{t("roleUpdatedMsg")} !</div>}
      {assignRoleResponse?.status !== 200 && (
        <div className="cd-err-msg">{assignRoleResponse?.data?.detail?.detail[0]?.msg}</div>
      )}
    </div>
  );
};

export default OrganisationAssignRole;
