import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";

import axiosFetch from "../../axiosConfig";
import { ORGANISATION_CREATE } from "../../api";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Heading2 from "../../components/headings/Heading2";
import InputField from "../../components/inputs/InputField";
import Label1 from "../../components/labels/Label1";
import Button1 from "../../components/buttons/Button1";
import Loader2 from "../../components/loaders/Loader2";
import Modal from "../../components/modal/Modal";

const OrganisationCreate = () => {
  const navigate = useNavigate();
  // constant to use use translation hook
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [createOrgResponse, setCreateOrgResponse] = useState({});
  const [showSkipWarning, setShowSkipWarning] = useState(false);
  const [skipDriveConnection, setSkipDriveConnection] = useState(false);
  const [googleLoginResponse, setGoogleLoginResponse] = useState();
  const [orgTitle, setOrgTitle] = useState("");
  const [orgSlug, setOrgSlug] = useState("");

  const createOrgPayload = {
    title: orgTitle,
    code: googleLoginResponse?.code,
    slug: orgSlug,
  };

  //call the API of create organisation
  const handleCreateOrganisation = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(ORGANISATION_CREATE, createOrgPayload);
      setCreateOrgResponse(resp);
      setLoader(false);
      if (resp.status === 201) {
        setTimeout(() => {
          navigate("/all-organisations");
        }, 2000);
      }
    } catch (error) {
      setCreateOrgResponse(error.response);
      setLoader(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      setGoogleLoginResponse(response);
      setSkipDriveConnection(true);
    },
    flow: "auth-code",
    scope: "profile email https://www.googleapis.com/auth/drive",
  });

  // constant to disable the create button if title not entered
  const isDisabled =
    orgTitle.length === 0 || !skipDriveConnection || orgSlug.length < 5 ? true : false;

  return (
    <DashboardWrapper>
      <Heading2>{t("organizationCreate")}</Heading2>
      <div className="cd-input-container">
        <Label1>{t("name")}: </Label1>
        <InputField
          name="cd_org_title"
          placeholder={t("placeholderOrgTitle")}
          onChange={(e) => setOrgTitle(e.target.value)}
        ></InputField>
      </div>
      <div className="cd-input-container" style={{ position: "relative" }}>
        <Label1>
          Slug:
          <span className="cd-organization-slug-info">&#128712;</span>
          <div className="cd-organization-info-msg">{t("slugInfoMsg")}</div>
        </Label1>
        <InputField
          placeholder={t("placeholderOrgSlug")}
          name="cd_organization_slug"
          onChange={(e) => setOrgSlug(e.target.value)}
        ></InputField>
      </div>
      <Label1>Google Drive: </Label1>
      <div className="cd-drive-connection-container clearfix">
        <Button1 className="cd-drive-conn-btn" onClick={() => login()}>
          {t("connectDrive")}
        </Button1>
        <div onClick={() => setShowSkipWarning(true)} className="cd-err-msg">
          {skipDriveConnection ? t("skipped") : t("skip")}
        </div>
      </div>
      {showSkipWarning && (
        <Modal handleModal={() => setShowSkipWarning(false)}>
          <div>{t("warningSkipDrive")} </div>
          <Button1
            onClick={() => {
              setSkipDriveConnection(true);
              setShowSkipWarning(false);
            }}
          >
            {t("yes")}
          </Button1>
          <Button1
            onClick={() => {
              setSkipDriveConnection(false);
              setShowSkipWarning(false);
            }}
          >
            {t("no")}
          </Button1>
          <br />
          <div className="cd-err-msg">{t("alertSkipDrive")}</div>
        </Modal>
      )}
      {createOrgResponse?.status === 201 && (
        <div className="cd-success-msg">{t("orgAddedMsg")} </div>
      )}
      {createOrgResponse?.data?.detail && (
        <div className="cd-err-msg">{createOrgResponse?.data?.detail[0]?.msg}</div>
      )}
      {createOrgResponse?.data?.detail?.detail && (
        <div className="cd-err-msg">{createOrgResponse?.data?.detail?.detail[0]?.msg}</div>
      )}
      <Button1 onClick={handleCreateOrganisation} disabled={isDisabled}>
        {t("create")} {loader && <Loader2 />}
      </Button1>
    </DashboardWrapper>
  );
};

export default OrganisationCreate;
