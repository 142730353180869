import { React, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import {
  TmADD_CUSTOM_COLUMN_EXP_VALUE,
  TmASSIGN_CUSTOM_COLUMN_VALUES,
  TmDELETE_CUSTOM_COLUMN_EXP_VALUE,
} from "../../api";
import axiosFetch from "../../axiosConfig";
import { getProjectDetails, getProjectTasks } from "../../redux/slice/taskManagementSlice";

import Button1 from "../../components/buttons/Button1";
import Heading2 from "../../components/headings/Heading2";
import InputField from "../../components/inputs/InputField";
import Loader2 from "../../components/loaders/Loader2";
import Label1 from "../../components/labels/Label1";
import Modal from "../../components/modal/Modal";

const TmUpdateCustomColumnValues = (props) => {
  const dispatch = useDispatch();
  // Extract the "value" from each object in props.column.values
  const expectedColValues = useMemo(() => {
    return props.column.values.map((item) => {
      return {
        value: item.value,
        value_id: item.vuid,
      };
    });
  }, [props.taskUpdated]);

  const [columnValue, setColumnValue] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [addValueResponse, setAddValueResponse] = useState();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [valueToBeDelete, setValueToBeDelete] = useState(false);
  const [deleteKeyword, setDeleteKeyword] = useState("");
  const [deleteValueResponse, setDeleteValueResponse] = useState();
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState();

  // call the API of Update Custom Column values
  let addColumnValuePayload = {
    column_id: props.column.cuid,
    values: columnValue,
  };
  const addColumnValues = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(TmADD_CUSTOM_COLUMN_EXP_VALUE, addColumnValuePayload);
      setAddValueResponse(resp?.data);
      if (resp.status === 201) {
        // update the project details in redux state
        dispatch(
          getProjectDetails({
            projectId: props.projectId,
          })
        );
        props.taskUpdated();
        setLoader2(false);
      }
    } catch (error) {
      setAddValueResponse(error.response);
      setLoader2(false);
    }
  };

  // call the API of delete custom column value
  let deleteColumnValuePayload = {
    column_uid: props.column.cuid,
    value_uid: valueToBeDelete.value_id,
    keyword: deleteKeyword,
  };
  const deleteColumnValue = async () => {
    setLoader3(true);
    try {
      const resp = await axiosFetch.delete(TmDELETE_CUSTOM_COLUMN_EXP_VALUE, {
        data: deleteColumnValuePayload,
      });
      setDeleteValueResponse(resp);
      props.taskUpdated();
    } catch (error) {
      setDeleteValueResponse(error.response);
    } finally {
      setLoader3(false);
    }
  };

  // constant to disable the submit button
  const isDisabled = columnValue?.length === 0 ? true : false;
  // constant to disable the delete column value button
  const isDisabledDelete = deleteKeyword !== "Delete" ? true : false;

  // function to call the API of assign custom column value
  let assignColumnValuePayload = {
    task_id: props.task.tuid,
    column_id: props.column.cuid,
    value_id: "",
  };
  const assignColumnValues = async (value_id) => {
    setLoader2(true);
    assignColumnValuePayload.value_id = value_id; // store received value_id in payload object
    try {
      const resp = await axiosFetch.post(TmASSIGN_CUSTOM_COLUMN_VALUES, assignColumnValuePayload);
      if (resp.status === 201) {
        dispatch(
          getProjectTasks({
            projectId: props.projectId,
          })
        );
      }
      setLoader2(false);
    } catch (error) {
      // console.log(error.response);
      setLoader2(false);
    }
  };
  return (
    <>
      <div>
        <Heading2 align="center">Update Column Values</Heading2>
      </div>
      <div>Current value : {props.value}</div>
      <div>
        {expectedColValues.length < 1 && (
          // show msg if existing column expected value are 0
          <div>Click on edit to add values</div>
        )}

        {/* show value as radio input if edit mode in close */}

        {!editMode &&
          expectedColValues.map((columnValue, index) => {
            // console.log("column value",columnValue);
            return (
              <div className="cd-input-container" key={index}>
                <InputField
                  type="radio"
                  id={columnValue.value_id}
                  name="columnValues"
                  value={columnValue.value_id}
                  onChange={(e) => assignColumnValues(e.target.value)}
                  disabled={props.value === columnValue.value ? true : false}
                />
                <Label1 htmlFor={columnValue.value_id} style={{ display: "inline" }}>
                  {columnValue.value}
                </Label1>
              </div>
            );
          })}
        <div>
          <Button1 onClick={() => setEditMode(!editMode)}>{!editMode ? "Edit" : "Close"}</Button1>
        </div>
      </div>
      {editMode && (
        <div>
          <div>Add a value</div>
          <InputField
            placeholder="Enter Value Title"
            name="cd_add_column_value"
            value={columnValue}
            onChange={(e) => setColumnValue(e.target.value)}
          />
          <Button1 onClick={() => addColumnValues()} disabled={isDisabled}>
            Add {loader2 && <Loader2 />}
          </Button1>
          {expectedColValues.map((columnValue, index) => {
            return (
              <div key={index} className="cd-input-container">
                {columnValue.value}{" "}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setValueToBeDelete({
                      value_name: columnValue.value,
                      value_id: columnValue.value_id,
                    })
                  }
                >
                  &times;
                </span>
              </div>
            );
          })}
          {valueToBeDelete && (
            <Modal
              handleModal={() => {
                setValueToBeDelete(false);
                setDeleteConfirm(false);
                setDeleteKeyword("");
                setDeleteValueResponse();
              }}
            >
              <div>
                <Heading2>Delete Value</Heading2>
                <div>Are you sure want to delete value {valueToBeDelete.value_name} ?</div>
                <Button1 onClick={() => setDeleteConfirm(true)}>Yes</Button1>
                <Button1
                  onClick={() => {
                    setValueToBeDelete(false);
                    setDeleteConfirm(false);
                    setDeleteKeyword("");
                    setDeleteValueResponse();
                  }}
                >
                  No
                </Button1>
                {deleteConfirm && (
                  <div>
                    <InputField
                      onChange={(e) => setDeleteKeyword(e.target.value)}
                      name="cd_delete_value_keyword"
                      placeholder="Please enter 'Delete'"
                    ></InputField>
                    <Button1 onClick={deleteColumnValue} disabled={isDisabledDelete}>
                      Delete {loader3 && <Loader2 />}
                    </Button1>
                  </div>
                )}
                {deleteValueResponse?.status === 200 && (
                  <div className="cd-success-msg">Value deleted!</div>
                )}
                {deleteValueResponse?.data?.detail && (
                  <div className="cd-err-msg">{deleteValueResponse?.data?.detail[0]?.msg}</div>
                )}
                {deleteValueResponse?.data?.detail?.detail && (
                  <div className="cd-err-msg">
                    {deleteValueResponse?.data?.detail?.detail[0]?.msg}
                  </div>
                )}
              </div>
            </Modal>
          )}
        </div>
      )}
      <div className="cd-msg-container">
        {addValueResponse?.title && <div className="cd-success-msg">Values Added Successfully</div>}
        {addValueResponse?.data?.detail && (
          <div className="cd-err-msg">{addValueResponse?.data?.detail[0]?.msg}</div>
        )}
        {addValueResponse?.data?.detail?.detail && (
          <div className="cd-err-msg">{addValueResponse?.data?.detail?.detail[0]?.msg}</div>
        )}
      </div>
    </>
  );
};

export default TmUpdateCustomColumnValues;
