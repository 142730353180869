import { React, useState } from "react";
import Button1 from "../buttons/Button1";
import Label1 from "../labels/Label1";
import InputField from "../inputs/InputField";
import Loader2 from "../loaders/Loader2";

/**
 * Component for JSX to add and apply coupons
 * @param {string} couponCode - The coupon code
 * @param {function} onChange - function to handle the onChange event of input
 * @param {function} applyCoupon - function to call after enter coupon code
 * @param {function} loader - to show while apply coupon API called
 * @returns {JSX.Element} - an UI to enter and submit coupon code
 */

const ApplyCoupon = (props) => {
  const [showApplyCoupon, setShowApplyCoupon] = useState(false);
  return (
    <>
      <div className="cd-input-container">
        <Button1 onClick={() => setShowApplyCoupon(!showApplyCoupon)}>Have a coupon ?</Button1>
      </div>
      {showApplyCoupon && (
        <>
          <div className="cd-input-container">
            <Label1>Coupon Code: </Label1>
            <InputField
              placeholder="Enter coupon code"
              name="cd_apply_coupon"
              value={props.couponCode}
              onChange={props.onChange}
            ></InputField>
          </div>
          <Button1 onClick={props.applyCoupon}>Apply {props.loader && <Loader2 />}</Button1>
        </>
      )}
    </>
  );
};

export default ApplyCoupon;
