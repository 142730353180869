import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axiosFetch from "../../axiosConfig";
import { GET_ALL_SUBSCRIPTIONS, CALCULATE_AMOUNT } from "../../api";

export const getAllSubscription = createAsyncThunk("getAllSubscription", async (data) => {
  try {
    const resp = await axiosFetch.get(
      GET_ALL_SUBSCRIPTIONS + `?limit=${data.limit}&offset=${data.offset}`
    );
    // console.log(resp);
    return resp;
  } catch (error) {
    return error?.response;
  }
});

export const calculateAmount = createAsyncThunk("calculateAmount", async (data) => {
  try {
    const resp = await axiosFetch.post(CALCULATE_AMOUNT, data);
    return resp;
  } catch (error) {
    return error.response;
  }
});

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    getAllSubscription: {
      allSubscriptions: [],
      error: {},
      isLoading: false,
    },
    calculateAmountData: {
      amountDistribution: {},
      error: {},
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSubscription.fulfilled, (state, { payload }) => {
      state.getAllSubscription.allSubscriptions = payload?.data;
    });
    builder.addCase(getAllSubscription.rejected, (state, { payload }) => {
      state.getAllSubscription.error = payload;
    });

    builder.addCase(calculateAmount.fulfilled, (state, { payload }) => {
      state.calculateAmountData.amountDistribution = payload.data;
    });
    builder.addCase(calculateAmount.rejected, (state, { payload }) => {
      state.calculateAmountData.error = payload;
    });
  },
});

export default subscriptionSlice.reducer;
