import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Heading2 from "../../components/headings/Heading2";
import InputField from "../../components/inputs/InputField";
import Label1 from "../../components/labels/Label1";
import Button1 from "../../components/buttons/Button1";
import axiosFetch from "../../axiosConfig";
import { TmADD_CUSTOM_COLUMN } from "../../api";
import { getProjectDetails } from "../../redux/slice/taskManagementSlice";

const TmCreateCustomColumn = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(); // constant to use this hook
  const [columnTitle, setColumnTitle] = useState("");
  const [columnType, setColumnType] = useState("text");
  const [createColumnResponse, setCreateColumnResponse] = useState();
  const projectId = props.projectId;
  // console.log(projectId);

  // call the API of create column
  let createColumnPayload = {
    project_id: projectId,
    title: columnTitle,
    type: columnType,
  };
  const createColumn = async () => {
    try {
      const resp = await axiosFetch.post(TmADD_CUSTOM_COLUMN, createColumnPayload);
      setCreateColumnResponse(resp.data);
      if (resp.status === 201) {
        dispatch(
          getProjectDetails({
            projectId: projectId,
          })
        );
      }
      // console.log(resp.data);
    } catch (error) {
      // console.log(error.response);
      setCreateColumnResponse(error.response);
    }
  };

  return (
    <>
      <Heading2 align="center">{t("createCustomColumn")} </Heading2>
      <div>
        <div className="cd-input-container">
          <Label1>{t("name")} :</Label1>
          <InputField
            placeholder={t("placeholderColumnTitle")}
            value={columnTitle}
            name="cd_column_title"
            onChange={(e) => setColumnTitle(e.target.value)}
          ></InputField>
        </div>
        <div className="cd-input-container">
          <Label1>{t("type")} :</Label1>
          <select
            name="columnType"
            id="columnType"
            className="cd-input-field"
            value={columnType}
            onChange={(e) => setColumnType(e.target.value)}
          >
            <option value="text">{t("text")} </option>
            <option value="date">{t("date")} </option>
          </select>
        </div>
        <Button1 onClick={createColumn}>{t("create")} </Button1>
        {createColumnResponse?.title && (
          <div className="cd-msg-container">
            <div className="cd-success-msg">{t("columnCreatedMsg")} !</div>
          </div>
        )}
        {createColumnResponse?.data?.detail && (
          <div className="cd-err-msg">{createColumnResponse?.data?.detail[0]?.msg}</div>
        )}
        {createColumnResponse?.data?.detail?.detail && (
          <div className="cd-err-msg cd-msg-container">
            {createColumnResponse?.data?.detail?.detail[0]?.msg}
          </div>
        )}
      </div>
    </>
  );
};

export default TmCreateCustomColumn;
