import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAllPermissions } from "../../redux/slice/organisationsSlice";
import { ORGANISATION_CREATE_ROLE } from "../../api";
import OrganisationWrapper from "../../components/wrappers/OrganisationWrapper";
import Heading2 from "../../components/headings/Heading2";
import InputField from "../../components/inputs/InputField";
import InputCheckbox from "../../components/inputs/InputCheckbox";
import Card1 from "../../components/cards/Card1";
import Label1 from "../../components/labels/Label1";
import Loader2 from "../../components/loaders/Loader2";
import Button1 from "../../components/buttons/Button1";
import axiosFetch from "../../axiosConfig";

const OrganisationCreateRole = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(); // const to use this hook

  const [roleTitle, setRoleTitle] = useState("");
  const [rolePermission, setRolePermission] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [createRoleResponse, setCreateRoleResponse] = useState("");

  // getting all permission from organisation state of redux
  const {
    getAllPermissions: { allPermissions },
  } = useSelector((state) => state.organisation);

  // dispatch the getAllPermissions action if allPermissions length is 0
  useEffect(() => {
    if (allPermissions.length === 0) {
      setLoader2(true);
      dispatch(getAllPermissions()).then(() => {
        setLoader2(false);
      });
    }
  }, []);

  // function to handle and store the permissions in an array
  const handlePermission = (permission) => {
    let index = rolePermission.indexOf(permission);
    if (index === -1) {
      let tempPermissions = [...rolePermission];
      tempPermissions.push(permission);
      setRolePermission(tempPermissions);
    } else {
      let tempPermissions = [...rolePermission];
      tempPermissions.splice(index, 1);
      setRolePermission(tempPermissions);
    }
  };
  // console.log(rolePermission);

  // constant to disable the Submit button if roleTitle or rolePermission is blank
  const isDisabled = rolePermission.length === 0 || roleTitle.length === 0 ? true : false;

  // calling the api the of create role
  let createRolePayload = {
    title: roleTitle,
    permissions: rolePermission,
  };
  // console.log(createRolePayload);
  const createRole = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(ORGANISATION_CREATE_ROLE, createRolePayload);
      setCreateRoleResponse(resp.data);
      setLoader(false);
    } catch (error) {
      // console.log(error.response);
      setCreateRoleResponse(error.response);
      setLoader(false);
    }
  };

  return (
    <OrganisationWrapper>
      <Card1>
        <Heading2>{t("orgRoleCreate")} </Heading2>
        <div>
          <div className="cd-input-field">
            <Label1> {t("name")}: </Label1>
            <InputField
              onChange={(e) => setRoleTitle(e.target.value)}
              name="cd_org_role_title"
              placeholder={t("placeHolderRole")}
            ></InputField>
          </div>
          <div className="" align="left">
            <Label1> {t("permissions")}: </Label1>
            {loader2 ? (
              <>
                <div>
                  <Loader2 />
                </div>
                <div className="cd-success-msg">{t("gettingPermissions")}... </div>
              </>
            ) : (
              allPermissions?.map((permission, index) => (
                <div key={index}>
                  <InputCheckbox
                    value={permission.codename}
                    id={permission.codename}
                    name={permission.codename}
                    onChange={(e) => handlePermission(e.target.value)}
                  ></InputCheckbox>
                  <label htmlFor={permission.codename}> {permission.permission} </label>
                </div>
              ))
            )}
          </div>
          {createRoleResponse?.ruid && (
            <div className="cd-success-msg">{t("roleCreatedMsg")} !</div>
          )}
          {createRoleResponse?.data?.detail && (
            <div className="cd-err-msg">{createRoleResponse?.data?.detail[0]?.msg}</div>
          )}
          <Button1 disabled={isDisabled} onClick={createRole}>
            {t("submit")} {loader && <Loader2 />}
          </Button1>
        </div>
      </Card1>
    </OrganisationWrapper>
  );
};

export default OrganisationCreateRole;
