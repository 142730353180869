import translationEN from "./translations/en.json";
import translationHI from "./translations/hi.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// getting default user language from local storage
const lang = localStorage.getItem("language");

// creating object of imported language files as a variable, used for language instance
const resources = {
  en: {
    translation: translationEN,
  },
  hi: {
    translation: translationHI,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: lang ? lang : "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
