import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import axiosFetch from "../../axiosConfig";
import { TmASSIGN_TAG } from "../../api";
import { getAllTags, getProjectTasks } from "../../redux/slice/taskManagementSlice";
import Heading2 from "../../components/headings/Heading2";
import InputCheckbox from "../../components/inputs/InputCheckbox";
import Loader from "../../components/loaders/Loader";
import Button1 from "../../components/buttons/Button1";
import Loader2 from "../../components/loaders/Loader2";
import TmCreateTag from "./TmCreateTag";
import Modal from "../../components/modal/Modal";
import Label1 from "../../components/labels/Label1";

const TmAssignTag = ({ task, projectId, limit, currentPage }) => {
  const dispatch = useDispatch();
  const [selectedTags, setSelectedTags] = useState(() => {
    return task.tags_assigned.map((tag) => tag.tag.tuid);
  });
  const [assignTagResponse, setAssignTagResponse] = useState({});
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [showCreateTag, setShowCreateTag] = useState(false);

  // getting tags from redux state
  const {
    getTags: { allTags },
  } = useSelector((state) => state.taskManagement);

  // to get all the tags
  useEffect(() => {
    setLoader(true);
    dispatch(
      getAllTags({
        projectId: projectId,
      })
    ).then(() => {
      setLoader(false);
    });
  }, []);

  // function to create the tag id array for payload
  const addTags = (value) => {
    const tempSelectedTags = [...selectedTags]; //store previous tag ids in a temp variable
    let index = tempSelectedTags.indexOf(value); //get the index of passed value
    if (index === -1) {
      tempSelectedTags.push(value); //if value not present in tag ids array then push it
    } else {
      tempSelectedTags.splice(index, 1); // if value already present then splice(remove) it
    }
    setSelectedTags(tempSelectedTags); // update the tag ids array
  };

  // to disable the assign button to prevent api call tag ids array is blank
  const isDisabled = selectedTags.length === 0 ? true : false;

  //function to call the API of assign tag
  let assignTagPayload = {
    tag_ids: selectedTags,
    task_id: task.tuid,
  };
  const assignTag = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(TmASSIGN_TAG, assignTagPayload);
      setAssignTagResponse(resp);
      if (resp.status === 201) {
        // refresh the task list state
        dispatch(
          getProjectTasks({
            projectId: projectId,
            limit: limit,
            offset: limit * (currentPage - 1),
          })
        );
      }
      setLoader2(false);
    } catch (error) {
      // console.log(error);
      setAssignTagResponse(error.response);
      setLoader2(false);
    }
  };

  return (
    <div>
      <Heading2 align="center">Assign Tag : {task?.title}</Heading2>
      <Button1 onClick={() => setShowCreateTag(true)}>Create Tag</Button1>
      {showCreateTag && (
        <Modal handleModal={() => setShowCreateTag(false)}>
          <TmCreateTag projectId={projectId} />
        </Modal>
      )}

      {allTags && allTags?.result?.length < 1 && (
        <div className="cd-success-msg">No tags found, please create!</div>
      )}

      {loader && <Loader />}

      {allTags?.result?.length > 0 &&
        allTags?.result?.map((tag, index) => {
          return (
            <div key={index} className="cd-input-container cd-tag-list-element">
              <InputCheckbox
                type="radio"
                name={`tagRadio+${tag.tuid}`}
                id={tag.tuid}
                value={tag.tuid}
                onChange={(e) => addTags(e.target.value)}
                checked={selectedTags.indexOf(tag.tuid) !== -1 ? true : false}
              ></InputCheckbox>{" "}
              <Label1
                className="cd-tag-title"
                htmlFor={tag.tuid}
                style={{ backgroundColor: `${tag.color}`, display: "inline" }}
              >
                {tag.title}
              </Label1>
            </div>
          );
        })}

      {/* show the success or error response */}
      {assignTagResponse?.status === 201 && (
        <div className="cd-success-msg">Tag Assigned Successfully!</div>
      )}
      {assignTagResponse?.data?.detail && (
        <div className="cd-err-msg">{assignTagResponse?.data?.detail[0]?.msg}</div>
      )}
      {assignTagResponse?.data?.detail?.detail && (
        <div className="cd-err-msg">{assignTagResponse?.data?.detail?.detail[0]?.msg}</div>
      )}
      <div className="cd-btn-container">
        <Button1 onClick={assignTag} disabled={isDisabled}>
          Assign {loader2 && <Loader2 />}
        </Button1>
      </div>
    </div>
  );
};

export default TmAssignTag;
