import { React, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ORGANISATION_GET_ROLES, ORGANISATION_UPDATE_ROLE } from "../../api";
import { getAllPermissions } from "../../redux/slice/organisationsSlice";
import axiosFetch from "../../axiosConfig";
import OrganisationWrapper from "../../components/wrappers/OrganisationWrapper";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import Modal from "../../components/modal/Modal";
import Label1 from "../../components/labels/Label1";
import InputField from "../../components/inputs/InputField";
import InputCheckbox from "../../components/inputs/InputCheckbox";
import Loader2 from "../../components/loaders/Loader2";

const OrganisationRoles = ({ setProgress }) => {
  const dispatch = useDispatch();
  const { slug } = useParams(); //get organisation id from url parameters
  const { t } = useTranslation(); // constant to use this hook
  const [roles, setRoles] = useState();
  const [loader2, setLoader2] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalRuid, setModalRuid] = useState("");

  // to create payload of update role
  const [roleTitle, setRoleTitle] = useState("");
  const [rolePermission, setRolePermission] = useState([]);
  const [updateRoleResponse, setUpdateRoleResponse] = useState("");

  useEffect(() => {
    // getting all the roles in an organisation
    const getOrgRoles = async () => {
      setProgress(40);
      try {
        const resp = await axiosFetch.get(ORGANISATION_GET_ROLES);
        setRoles(resp.data);
        setProgress(100);
      } catch (error) {
        setRoles(error.response);
        setProgress(100);
      }
    };
    getOrgRoles();

    // getting all permissions
    dispatch(getAllPermissions());
  }, [updateRoleResponse]);
  // console.log(roles);

  const {
    getAllPermissions: { allPermissions },
  } = useSelector((state) => state.organisation);
  // console.log(allPermissions);

  // function to handle the modal
  const handleModal = async (ruid, title, permissions) => {
    setOpenModal(!openModal);
    setModalRuid(ruid);
    setRoleTitle(title);
    let tempPermissions = [];
    // if current ruid(role) not have any permission then make rolePermission array blank
    if (permissions?.length === 0) setRolePermission([]);
    else {
      permissions?.map((permission) => {
        tempPermissions.push(permission.permission.codename);
        setRolePermission(tempPermissions);
        return null;
      });
    }
  };

  // function to handle permission array
  const handlePermissions = (value) => {
    let permIndex = rolePermission.indexOf(value);
    if (permIndex === -1) {
      let tempPermissions = [...rolePermission];
      tempPermissions.push(value);
      setRolePermission(tempPermissions);
    } else {
      let tempPermissions = [...rolePermission];
      tempPermissions.splice(permIndex, 1);
      setRolePermission(tempPermissions);
    }
  };
  // console.log(rolePermission);

  // call the API of update role
  let updateRolePayload = {
    ruid: modalRuid,
    title: roleTitle,
    permissions: rolePermission,
  };
  const updateRole = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(ORGANISATION_UPDATE_ROLE, updateRolePayload);
      setUpdateRoleResponse(resp.data);
      setLoader2(false);
      // console.log(resp);
    } catch (error) {
      setUpdateRoleResponse(error.response);
      setLoader2(false);
      // console.log(error.response);
    }
  };

  return (
    <OrganisationWrapper>
      <div>
        <Link to={`/${slug}/create-role`} className="cd-common-anchor-tag">
          {t("addRole")}
        </Link>
      </div>
      <Heading2>{t("orgRole")} </Heading2>
      {roles?.roles?.length !== 0 ? (
        <div>
          <table className="role-table" cellSpacing="15px">
            <thead>
              <tr>
                <th>{t("roleName")} </th>
                <th>{t("update")} </th>
              </tr>
            </thead>
            <tbody>
              {roles?.roles?.map((data, index) => (
                <tr key={index}>
                  <td>{data?.title}</td>
                  <td>
                    <Button1
                      onClick={() => handleModal(data?.ruid, data?.title, data.permissions)}
                      className="cd-btn"
                    >
                      {t("update")}
                    </Button1>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {openModal && (
            <Modal handleModal={handleModal}>
              {roles?.roles?.map(
                (role, index) =>
                  role.ruid === modalRuid && (
                    <div key={index}>
                      <h3 align="center">
                        {t("role")} : {role.title}
                      </h3>
                      <div className="cd-input-field">
                        <Label1>{t("name")} :</Label1>
                        <InputField
                          placeholder={t("placeHolderRole")}
                          value={roleTitle}
                          name="cd_org_update_role_title"
                          onChange={(e) => setRoleTitle(e.target.value)}
                        ></InputField>
                      </div>
                      <div className="cd-input-field">
                        <Label1>{t("permissions")} :</Label1>
                        {allPermissions.map((permissions, index) => (
                          <div key={index} align="left">
                            <InputCheckbox
                              value={permissions.codename}
                              id={permissions.codename}
                              name={permissions.codename}
                              onChange={(e) => handlePermissions(e.target.value)}
                              checked={rolePermission.includes(permissions.codename) ? true : false}
                            ></InputCheckbox>
                            <label htmlFor={permissions.codename}> {permissions.permission} </label>
                          </div>
                        ))}
                      </div>
                      {updateRoleResponse?.title && (
                        <div className="cd-success-msg" align="center">
                          {t("roleUpdatedMsg")} !
                        </div>
                      )}
                      {updateRoleResponse?.data?.detail && (
                        <div className="cd-err-msg">{updateRoleResponse?.data?.detail[0]?.msg}</div>
                      )}
                      {updateRoleResponse?.data?.detail && (
                        <div className="cd-err-msg">
                          {updateRoleResponse?.data?.detail?.detail[0]?.msg}
                        </div>
                      )}
                      <div align="center">
                        <Button1 onClick={updateRole}>
                          {t("update")} {loader2 && <Loader2 />}
                        </Button1>
                      </div>
                    </div>
                  )
              )}
            </Modal>
          )}
        </div>
      ) : (
        <div>{t("noRoleMsg")} !</div>
      )}
      {roles?.data?.detail && <div className="cd-err-msg">{roles?.data?.detail[0].msg}</div>}
      {roles?.data?.detail?.detail && (
        <div className="cd-err-msg">{roles?.data?.detail?.detail[0]?.msg}</div>
      )}
    </OrganisationWrapper>
  );
};

export default OrganisationRoles;
