import { React, useState } from "react";
import { useDispatch } from "react-redux";

import { TmUPDATE_TASK_GROUP } from "../../api";
import axiosFetch from "../../axiosConfig";
import Label1 from "../../components/labels/Label1";
import { getAllTaskGroups } from "../../redux/slice/taskManagementSlice";
import InputField from "../../components/inputs/InputField";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import Loader2 from "../../components/loaders/Loader2";

const TmUpdateTaskGroup = (props) => {
  const dispatch = useDispatch();
  const [taskGroupTitle, setTaskGroupTitle] = useState(props.taskGroup.title);
  const [updateTaskGroupResponse, setUpdateTaskGroupResponse] = useState();
  const [showDeleteInput, setShowDeleteInput] = useState(false);
  const [deleteInput, setDeleteInput] = useState("");
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [loader, setLoader] = useState(false);
  // Call the API of update task group
  let updateTaskGroupPayLoad = {
    group_id: props.taskGroup.guid,
    title: taskGroupTitle,
    is_deleted: false,
  };

  const updateTaskGroup = async (is_deleted) => {
    if (is_deleted == true) {
      // set is_deleted true to delete the task group
      updateTaskGroupPayLoad.is_deleted = true;
    }
    setLoader(true);
    try {
      const resp = await axiosFetch.post(TmUPDATE_TASK_GROUP, updateTaskGroupPayLoad);
      setUpdateTaskGroupResponse(resp.data);
      setLoader(false);
      if (resp.status === 201) {
        // refresh the task group list after updation
        dispatch(
          getAllTaskGroups({
            projectId: props.puid,
            limit: props.limit,
            offset: props.offset,
          })
        );
      }
    } catch (error) {
      setUpdateTaskGroupResponse(error.response);
      setLoader(false);
    }
  };

  // constant to disable the submit button
  const isDisabled = deleteInput === "Delete" ? false : true;
  return (
    <div>
      <div>
        <Heading2 align="center">Update Task Group</Heading2>
      </div>
      <div className="cd-input-container">
        <Label1>Title:</Label1>
        <InputField
          placeholder="Enter task-group title"
          name="cd_task_group_title"
          value={taskGroupTitle}
          onChange={(e) => setTaskGroupTitle(e.target.value)}
        ></InputField>
      </div>
      {updateTaskGroupResponse?.guid && <div className="cd-success-msg">Task Group Updated!</div>}
      <div>
        <Button1 onClick={updateTaskGroup}>Update {loader && <Loader2 />}</Button1>
      </div>
      <Button1 onClick={() => setShowDeleteWarning(!showDeleteWarning)}>
        <span className="cd-err-msg">Delete</span>
      </Button1>
      {/* code block to show delete warning */}
      {showDeleteWarning && (
        <div>
          <div>Are you sure want to delete this task group ?</div>
          <Button1 onClick={() => setShowDeleteInput(!showDeleteInput)}>Yes</Button1>
          <Button1
            onClick={() => {
              setShowDeleteWarning(false);
              setShowDeleteInput(false);
              setDeleteInput("");
            }}
          >
            No
          </Button1>
          {/* show delete input */}
          {showDeleteInput && (
            <div>
              <InputField
                onChange={(e) => setDeleteInput(e.target.value)}
                name="cd_task_group_delete_keyword"
                placeholder="Please enter 'Delete'"
              ></InputField>
              <Button1 disabled={isDisabled} onClick={() => updateTaskGroup(true)}>
                Submit
              </Button1>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TmUpdateTaskGroup;
