import { React, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getProjectUsers, getProjectPermissions } from "../../redux/slice/taskManagementSlice";
import { IMAGE_PATH_PREFIX, TmUPDATE_PROJECT_USER, TmREMOVE_PROJECT_USER } from "../../api";
import TmAddProjectUser from "./TmAddProjectUser";
import OrganisationWrapper from "../../components/wrappers/OrganisationWrapper";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import Modal from "../../components/modal/Modal";
import Loader2 from "../../components/loaders/Loader2";
import axiosFetch from "../../axiosConfig";
import TmProjectUserPermissions from "./TmProjectUserPermissions";

const TmProjectUsers = ({ setProgress }) => {
  const { t } = useTranslation(); // const to use this hook
  const [loader, setLoader] = useState(true);
  const [showAddUser, setShowAddUser] = useState(false);
  const [userAdded, setUserAdded] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [deactivateUserInfo, setDeactivateUserInfo] = useState({});
  const [showDeactivateUserWarning, setShowDeactivateUserWarning] = useState(false);
  const [userToRemove, setUserToRemove] = useState(false);
  const [removeUserResp, setRemoveUserResp] = useState();
  const [deactivateUserResp, setDeactivateUserResp] = useState();
  const [activateUserResp, setActivateUserResp] = useState();
  const [loader2, setLoader2] = useState(false);
  // get project id from url params
  const { puid } = useParams();
  // constant to use useDispatch() hook
  const dispatch = useDispatch();

  // get the project users list from redux state
  const {
    getProjectUsers: { projectUsers },
  } = useSelector((state) => state.taskManagement);

  // dispatch the action to get all project user
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    dispatch(
      getProjectUsers({
        projectId: puid,
      })
    ).then(() => {
      setProgress(100);
      setLoader(false);
    });
  }, [userAdded]);

  // dispatch action to get project user permissions
  useEffect(() => {
    if (allProjectPermissions.length === 0) {
      setLoader(true);
      dispatch(getProjectPermissions()).then(() => {
        setLoader(false);
      });
    }
  }, []);

  // get user permissions from redux
  const {
    getProjectPermissions: { allProjectPermissions },
  } = useSelector((state) => state.taskManagement);

  const projectName = useMemo(() => {
    return projectUsers?.project_users?.[0]?.project?.title;
  }, [projectUsers]);

  // function to call the API of deactive user
  let deactivateUserPayload = {
    is_active: false,
    user_uid: deactivateUserInfo?.user_uid,
    username: deactivateUserInfo?.username,
    project_uid: puid,
  };
  const deactivateUser = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(TmUPDATE_PROJECT_USER, deactivateUserPayload);
      setDeactivateUserResp(resp.data);
      setLoader2(false);
      setUserAdded(!userAdded);
    } catch (error) {
      setLoader2(false);
      setDeactivateUserResp(error.response);
    }
  };

  const acitvateUser = async (is_active, user_uid, index) => {
    deactivateUserPayload.is_active = is_active;
    deactivateUserPayload.user_uid = user_uid;
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(TmUPDATE_PROJECT_USER, deactivateUserPayload);
      setActivateUserResp({ resp: resp.data, index: index });
      setLoader2(false);
      setUserAdded(!userAdded);
    } catch (error) {
      setLoader2(false);
      setActivateUserResp({ resp: error.response, index: index });
    }
    setTimeout(() => {
      setActivateUserResp();
    }, 2000);
  };

  // function to call the API of remove-project-user
  let removeUserPayload = {
    project_uid: puid,
    user_id: userToRemove?.uuid,
  };
  const removeUser = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(TmREMOVE_PROJECT_USER, removeUserPayload);
      setRemoveUserResp(resp);
      setUserAdded(!userAdded);
    } catch (error) {
      setRemoveUserResp(error.response);
    } finally {
      setLoader2(false);
    }
  };

  function closeModal() {
    setShowDeactivateUserWarning(false);
    setDeactivateUserInfo({});
    setDeactivateUserResp();
    setRemoveUserResp();
    setUserToRemove();
  }

  return (
    <OrganisationWrapper>
      <Heading2>
        {" "}
        <span className={`${loader ? "not-appeared" : "appeared"}`}>{projectName}</span> :{" "}
        {t("projectUsers")}
      </Heading2>
      <div className={`${loader ? "not-appeared" : "appeared"}`}>
        <Button1 onClick={() => setShowAddUser(!showAddUser)}>{t("addUser")} </Button1>
        {showAddUser && (
          <Modal handleModal={() => setShowAddUser(false)}>
            <TmAddProjectUser
              setProgress={setProgress}
              projectId={puid}
              userAdded={() => setUserAdded(!userAdded)}
            ></TmAddProjectUser>
          </Modal>
        )}
        {showPermissionModal?.userId && (
          <Modal handleModal={() => setShowPermissionModal(false)}>
            <TmProjectUserPermissions
              projectId={puid}
              userId={showPermissionModal?.userId}
              userName={showPermissionModal?.userName}
            ></TmProjectUserPermissions>
          </Modal>
        )}
        <div className="cd-project-users-list-container">
          {projectUsers?.project_users &&
            projectUsers.project_users.map((user, index) => (
              <div key={index} className="cd-project-user-list-element">
                <div>
                  <img
                    src={`${IMAGE_PATH_PREFIX + user?.user?.profile_photo}`}
                    alt="user-thumb"
                    className="cd-user-profile-thumbnail"
                  />
                </div>
                <div>
                  {t("email")} : {user?.user?.email}
                </div>
                <div>
                  {t("username")} : {user?.user?.username}
                  {!user?.is_active && <span className="cd-err-msg">({t("deactivated")})</span>}
                </div>
                {/* if user not have permission to read project permissions */}
                {!allProjectPermissions?.data?.detail && (
                  <span
                    className="cd-success-msg"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setShowPermissionModal({
                        userId: user?.user?.uuid,
                        userName: user?.user?.username,
                      })
                    }
                  >
                    {t("permissions")}
                  </span>
                )}
                <div className="cd-deactivate-user">
                  {user?.is_active ? (
                    <>
                      <span
                        className="cd-err-msg"
                        onClick={() => {
                          setShowDeactivateUserWarning(true);
                          setDeactivateUserResp();
                          setDeactivateUserInfo({
                            user_uid: user?.user?.uuid,
                            username: user?.user?.username,
                          });
                        }}
                        style={{ cursor: "pointer", marginRight: "10px" }}
                      >
                        {t("deactivate")}
                      </span>
                      <span
                        onClick={() => setUserToRemove(user?.user)}
                        className="cd-err-msg"
                        style={{ cursor: "pointer" }}
                      >
                        {t("remove")}
                      </span>
                    </>
                  ) : (
                    <span
                      className="cd-success-msg"
                      onClick={() => {
                        acitvateUser("true", user?.user?.uuid, index);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {t("activate")}
                    </span>
                  )}
                </div>
                {activateUserResp?.index === index && (
                  <div className="cd-success-msg">
                    User : {activateUserResp.resp.username} Updated!
                  </div>
                )}
              </div>
            ))}
        </div>

        {projectUsers?.data?.detail && (
          <div className="cd-err-msg">{projectUsers?.data?.detail[0]?.msg}</div>
        )}
        {projectUsers?.data?.detail?.detail && (
          <div className="cd-err-msg">{projectUsers?.data?.detail?.detail[0]?.msg}</div>
        )}
        {showDeactivateUserWarning && (
          <div>
            <Modal handleModal={closeModal}>
              <div>
                {t("areYouSureDeactivateUser")} '{deactivateUserInfo?.username}'?
              </div>
              <Button1 onClick={() => deactivateUser("false")}>
                {t("yes")} {loader2 && <Loader2 />}
              </Button1>
              <Button1 onClick={closeModal}>{t("no")} </Button1>
              {deactivateUserResp?.uuid && (
                <div className="cd-success-msg">{t("userUpdated")} !</div>
              )}
              {deactivateUserResp?.data?.detail && (
                <div className="cd-err-msg">{deactivateUserResp?.data?.detail[0]?.msg}</div>
              )}
              {deactivateUserResp?.data?.detail?.detail && (
                <div className="cd-err-msg">{deactivateUserResp?.data?.detail?.detail[0]?.msg}</div>
              )}
            </Modal>
          </div>
        )}
        {userToRemove?.email && (
          <Modal handleModal={() => closeModal()}>
            <div>{t("removeUserWarning", { username: userToRemove?.username, projectName })}</div>
            <Button1 onClick={() => removeUser()}>
              {t("yes")} {loader2 && <Loader2 />}
            </Button1>
            <Button1 onClick={() => closeModal()}>{t("no")} </Button1>
            {removeUserResp?.status === 200 && (
              <div className="cd-success-msg">{t("userUpdated")} !</div>
            )}
            {removeUserResp?.data?.detail && (
              <div className="cd-err-msg">{removeUserResp?.data?.detail[0]?.msg}</div>
            )}
            {removeUserResp?.data?.detail?.detail && (
              <div className="cd-err-msg">{removeUserResp?.data?.detail?.detail[0]?.msg}</div>
            )}
          </Modal>
        )}
      </div>
    </OrganisationWrapper>
  );
};

export default TmProjectUsers;
