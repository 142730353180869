import React from "react";

const Label1 = (props) => {
  return (
    <label
      className={`cd-input-label ${props.className}`}
      htmlFor={props.htmlFor}
      style={props.style}
    >
      {props.children}
    </label>
  );
};

export default Label1;
