import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosFetch from "../../axiosConfig";
import {
  TmPROJECTS,
  TmGET_ALL_TASKS,
  TmGET_ALL_TASK_GROUPS,
  TmGET_TAGS,
  TmPROJECT_DETAILS,
  TmPROJECT_PERMISSIONS,
  TmGET_ALL_COMMENTS_IN_TASK,
  TmGET_NOTIFICATIONS,
  TmPROJECT_USERS,
} from "../../api";

export const getProjects = createAsyncThunk("getProjects", async (data) => {
  let url = TmPROJECTS;
  // adding the limit and offset if they are passed with payload
  if (data.limit) {
    url += `?limit=${data.limit}`;
  }
  if (data.offset) {
    url += `&offset=${data.offset}`;
  }
  try {
    const resp = await axiosFetch.get(url);
    return resp.data;
  } catch (error) {
    return error.response;
  }
});

// /**
//  * function to get the tasks inside a project
//  * @param {Number} puid - project id of project
//  * @param {Number} limit - number of rows limit
//  * @param {Number} offset - number of offset rows
//  * @returns {JSON} - JSON response from API
//  */
export const getProjectTasks = createAsyncThunk("getProjectTasks", async (data) => {
  let url = TmGET_ALL_TASKS + `?project_id=${data.projectId}`;
  // adding the limit and offset if they are passed with payload
  if (data.limit) {
    url += `&limit=${data.limit}`;
  }
  if (data.offset) {
    url += `&offset=${data.offset}`;
  }

  try {
    const resp = await axiosFetch.get(url);
    return resp.data;
  } catch (error) {
    return error.response;
  }
});

// To get all task groups
export const getAllTaskGroups = createAsyncThunk("getAllTaskGroups", async (data) => {
  let url = TmGET_ALL_TASK_GROUPS + `?project_id=${data.projectId}`;
  // adding the limit and offset if they are passed with payload
  if (data.limit) {
    url += `&limit=${data.limit}`;
  }
  if (data.offset) {
    url += `&offset=${data.offset}`;
  }
  try {
    const resp = await axiosFetch.get(url);
    return resp.data;
  } catch (error) {
    return error.response;
  }
});

// to get all project tags
export const getAllTags = createAsyncThunk("getAllTags", async (data) => {
  try {
    const resp = await axiosFetch.get(TmGET_TAGS + `?project=${data.projectId}`);
    return resp.data;
  } catch (error) {
    return error.response;
  }
});

// to get project details
export const getProjectDetails = createAsyncThunk("getProjectDetails", async (data) => {
  try {
    const resp = await axiosFetch.get(TmPROJECT_DETAILS + `/${data.projectId}`);
    return resp.data;
  } catch (error) {
    return error.response;
  }
});

// to get project users
export const getProjectUsers = createAsyncThunk("getProjectUsers", async (data) => {
  try {
    const resp = await axiosFetch.get(TmPROJECT_USERS + `?puid=${data.projectId}`);
    return resp.data;
  } catch (error) {
    return error.response;
  }
});

// to get all comments in task
export const getAllComments = createAsyncThunk("getAllComments", async (data) => {
  let url = TmGET_ALL_COMMENTS_IN_TASK + `/?tuid=${data.tuid}`;
  if (data.limit) {
    url += `&limit=${data.limit}`;
  }
  if (data.offset) {
    url += `&offset=${data.offset}`;
  }
  try {
    const resp = await axiosFetch.get(url);
    return resp.data;
  } catch (error) {
    return error.response;
  }
});

// to get all permissions in a project
export const getProjectPermissions = createAsyncThunk("getProjectPermissions", async () => {
  try {
    const resp = await axiosFetch.get(TmPROJECT_PERMISSIONS);
    return resp.data;
  } catch (error) {
    return error.response;
  }
});

// to get all notifications
export const getNotifications = createAsyncThunk("getNotifications", async (data) => {
  try {
    const resp = await axiosFetch.get(TmGET_NOTIFICATIONS);
    return resp.data;
  } catch (error) {
    return error.response;
  }
});

const taskManagementSlice = createSlice({
  name: "taskManagement",
  initialState: {
    getProjects: {
      allProjects: [],
    },
    getTasks: {
      allTasks: [],
      error: {},
    },
    getTaskGroups: {
      allTaskGroups: [],
      error: {},
    },
    getTags: {
      allTags: [],
      error: {},
    },
    getProjectDetails: {
      projectDetails: [],
      error: {},
    },
    getProjectUsers: {
      projectUsers: [],
    },
    getAllComments: {
      allComments: [],
      error: {},
    },
    getProjectPermissions: {
      allProjectPermissions: [],
    },
    getNotifications: {
      allNotifications: [],
      error: {},
    },
  },
  extraReducers: (builder) => {
    // to get the details of project
    builder.addCase(getProjects.fulfilled, (state, { payload }) => {
      state.getProjects.allProjects = payload;
    });
    builder.addCase(getProjects.rejected, (state, { payload }) => {
      state.getProjects.allProjects = payload;
    });

    // to get all the tasks
    builder.addCase(getProjectTasks.fulfilled, (state, { payload }) => {
      state.getTasks.allTasks = payload;
    });
    builder.addCase(getProjectTasks.rejected, (state, { payload }) => {
      state.getTasks.error = payload.data;
    });

    // to get all task groups
    builder.addCase(getAllTaskGroups.fulfilled, (state, { payload }) => {
      state.getTaskGroups.allTaskGroups = payload;
    });
    builder.addCase(getAllTaskGroups.rejected, (state, { payload }) => {
      state.getTaskGroups.error = payload.data;
    });

    // to get all tags
    builder.addCase(getAllTags.fulfilled, (state, { payload }) => {
      state.getTags.allTags = payload;
    });
    builder.addCase(getAllTags.rejected, (state, { payload }) => {
      state.getTags.error = payload;
    });

    // to get project details
    builder.addCase(getProjectDetails.fulfilled, (state, { payload }) => {
      state.getProjectDetails.projectDetails = payload;
    });
    builder.addCase(getProjectDetails.rejected, (state, { payload }) => {
      state.getProjectDetails.error = payload;
    });

    // to get the project user
    builder.addCase(getProjectUsers.fulfilled, (state, { payload }) => {
      state.getProjectUsers.projectUsers = payload;
    });
    builder.addCase(getProjectUsers.rejected, (state, { payload }) => {
      state.getProjectUsers.projectUsers = payload;
    });

    // to get comments
    builder.addCase(getAllComments.fulfilled, (state, { payload }) => {
      state.getAllComments.allComments = payload;
    });
    builder.addCase(getAllComments.rejected, (state, { payload }) => {
      state.getAllComments.error = payload;
    });

    // to get project permissions
    builder.addCase(getProjectPermissions.fulfilled, (state, { payload }) => {
      state.getProjectPermissions.allProjectPermissions = payload;
    });
    builder.addCase(getProjectPermissions.rejected, (state, { payload }) => {
      state.getProjectPermissions.allProjectPermissions = payload;
    });

    //to get notifications
    builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
      state.getNotifications.allNotifications = payload;
    });
    builder.addCase(getNotifications.rejected, (state, { payload }) => {
      state.getNotifications.allNotifications = payload.data;
    });
  },
});

export default taskManagementSlice.reducer;
