import React, { useEffect, useState } from "react";
import { UPDATE_PROFILE_PHOTO } from "../../api";
import { useTranslation } from "react-i18next";
import InputFile from "../../components/inputs/InputFile";
import Card1 from "../../components/cards/Card1";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import Loader from "../../components/loaders/Loader";
import axiosFetch from "../../axiosConfig";
const UploadProfilePhoto = () => {
  const [uploadedPhoto, setUploadedPhoto] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [loader, setLoader] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [disableUpdateBtn, setDisableUpdateBtn] = useState(false);
  const [validationError, setValidationError] = useState({
    size_error: "",
    extension_error: "",
  });
  const user = JSON.parse(localStorage.getItem("user"));
  // console.log(uploadedPhoto)

  // constant to use useTranslation()
  const { t } = useTranslation();

  // function to handle onChange event of file upload
  const handleFileChange = (file) => {
    if (file) {
      //regular expression to check image extension
      const allowedExtensions = /\.(png|jpg|gif|jpeg)$/i;
      const uploadError = {
        size_error: "",
        extension_error: "",
      };

      //if file size is greater than 5MB than update the uploadError.extension_error
      if (file.size > 5 * 1024 * 1024) {
        uploadError.size_error = `${t("profilePhotoSizeError")}`;
      }

      //if file size is greater than 5MB than update the uploadError.size_error
      if (!allowedExtensions.exec(file.name)) {
        uploadError.extension_error = `${t("profilePhotoExtensionError")}`;
      }
      setValidationError(uploadError);

      //disable the update button if uploadError object has any value and return
      if (uploadError.extension_error || uploadError.size_error) {
        setDisableUpdateBtn(true);
        return;
      }
      setUploadedPhoto(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  //disable the Update button if file is blank or not uploaded yet
  useEffect(() => {
    if (uploadedPhoto === "") {
      setDisableUpdateBtn(true);
    } else {
      setDisableUpdateBtn(false);
    }
  }, [uploadedPhoto]);
  // console.log(validationError);

  //function to call the upload profile picture API
  const uploadPicture = async () => {
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append("image", uploadedPhoto);

      const resp = await axiosFetch.post(UPDATE_PROFILE_PHOTO, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (resp.status === 200) {
        setLoader(false);
        setApiResponse(resp);

        // to update localStorage user.profile_photo key on successful response
        const tempUser = user;
        tempUser.profile_photo = resp?.data?.profile_photo + "?" + Math.random(1, 10);
        localStorage.setItem("user", JSON.stringify(tempUser));
      }
    } catch (error) {
      if (error) {
        setLoader(false);
        setApiResponse(error?.response);
      }
    }
  };
  // console.log(apiResponse)

  return (
    <div>
      <DashboardWrapper>
        <Card1>
          <div>
            <Heading2>{t("updateProfilePhoto")}</Heading2>
          </div>
          <div className="cd-user-profile-photo-container">
            {imagePreview ? (
              <img className="cd-user-profile-photo" src={imagePreview} alt="profile-photo" />
            ) : user?.profile_photo ? (
              <img
                className="cd-user-profile-photo"
                src={`https://codecms.codeholic.in/static/uploads/${user?.profile_photo}?${apiResponse?.data?.updated_at}`}
                height="30px"
                alt=""
              />
            ) : (
              <img className="cd-user-profile-photo" src="/user-128.png" alt="profile-photo" />
            )}
          </div>
          <div className="cd-input-container">
            <InputFile
              className="cd-input-field"
              name="cd_user_profile_pic"
              accept="image"
              onChange={(e) => handleFileChange(e.target.files[0])}
            ></InputFile>
          </div>
          {/* code block to show the validation error conditonally */}
          {validationError?.size_error && (
            <div className="cd-err-msg">{validationError.size_error}</div>
          )}
          {validationError?.extension_error && (
            <div className="cd-err-msg">{validationError.extension_error}</div>
          )}

          {/* code block to show the loader conditionally */}
          {loader && <Loader />}

          {/* code to show the API response coditionally */}
          {apiResponse?.status === 200 ? (
            <div className="cd-success-msg">{t("profilePhotoUpdatedMsg")}</div>
          ) : (
            <div className="cd-err-msg">{apiResponse?.data?.detail?.detail[0]?.msg}</div>
          )}
          <div>
            <Button1 className="cd-btn" onClick={uploadPicture} disabled={disableUpdateBtn}>
              {t("update")}
            </Button1>
          </div>
        </Card1>
      </DashboardWrapper>
    </div>
  );
};

export default UploadProfilePhoto;
