import React from "react";
import InputField from "../../components/inputs/InputField";
import Button1 from "../../components/buttons/Button1";
/**
 * A component for navigating to a specific page.
 * @param {number} inputPage - A constant in which you want to store the entered page value.
 * @param {function} onClick - The function to handle navigating to the entered page.
 * @param {function} onChange - The function to handle the value user entered
 * @returns {JSX.Element} - The page user want to go.
 */

const GoToPage = (props) => {
  return (
    <div className="cd-go-to-page-container">
      <div className="cd-go-to-page-wrapper">
        <div>
          <InputField
            onChange={props.onChange}
            name="cd_page_no"
            value={props.inputPage}
          ></InputField>
        </div>
        <div>
          <Button1 onClick={props.onClick}>Go</Button1>
        </div>
      </div>
    </div>
  );
};

export default GoToPage;
