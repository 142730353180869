import React from "react";

const InputField = (props) => {
  return (
    <input
      className={`cd-input-field ${props.className}`}
      id={props.id}
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
      autoComplete="off"
      checked={props.checked}
      disabled={props.disabled}
    ></input>
  );
};

export default InputField;
